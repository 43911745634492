<template>
  <div class="search-wrapper">
    <div class="input">
      <div class="search-box">
        <img class="search-icon" src="@/assets/images/svg/search.svg">
        <input type="text" v-model="queryString" class="search-input" :placeholder="placeHolder">
      </div>
      <div v-if="queryString" class="icon cursor-pointer" @click="clear">
        <img src="../assets/images/svg/close.svg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    placeHolder: String,
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      queryString: this.text
    }
  },
  watch: {
    queryString() {
      this.$emit('query', this.queryString);
    }
  },
  methods: {
    clear() {
      this.queryString = null;
    }
  }
}
</script>

<style scoped>
.search-wrapper {
  margin-left: auto;
}

.search-box {
  position: relative;
  width: 340px;
  padding: 12px 45px 12px 25px;
  border-bottom: 1px solid #BFBFBF;
}

.search-input {
  width: 100%;
  background: transparent;
  border: none;
  padding-right: 40px;
}

.search-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
}

.input {
  position: relative;
}

.icon {
  position: absolute;
  right: 40px;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
