import axios from '@/axios';
import Service from './Service';

const qs = require('qs');

export default class PartnerService extends Service {
  constructor(name = 'PartnerService', namespace = null, ...args) {
    super(name, namespace);
  }

  async addPartnerType(dto) {
    await axios.post('/api/v1/partner_types', dto);
  }

  async getPartnerTypes(params) {
    const response = await axios.get('/api/v1/partner_types', { params });
    return response.data;
  }

  async updatePartnerType(objType, id) {
    return axios.patch(`/api/v1/partner_types/${id}`, objType);
  }

  async deletePartnerType(id) {
    await axios.delete(`/api/v1/partner_types/${id}`)
  }

  async getPartnerType(id) {
    const response = await axios.get(`/api/v1/partner_types/${id}`);
    return response.data;
  }

  async addPartnerAttribute(dto) {
    await axios.post('/api/v1/partner_attributes', dto);
  }

  async getPartnerAttributes(params) {
    const response = await axios.get('/api/v1/partner_attributes', { params });
    return response.data;
  }

  async updatePartnerAttribute(objType, id) {
    return axios.patch(`/api/v1/partner_attributes/${id}`, objType);
  }

  async deletePartnerAttribute(id) {
    await axios.delete(`/api/v1/partner_attributes/${id}`)
  }

  async getPartnerAttribute(id) {
    const response = await axios.get(`/api/v1/partner_attributes/${id}`);
    return response.data;
  }

  async addPartner(dto) {
    await axios.post('/api/v1/partners', dto);
  }

  async getPartners(params) {
    const response = await axios.get('/api/v1/partners', {
      params: {
        ...params,
        is_admin_panel: true
      },
      paramsSerializer: par => qs.stringify(par, { arrayFormat: 'repeat' })
    });
    return response.data;
  }

  async updatePartner(partner, id) {
    return axios.patch(`/api/v1/partners/${id}`, partner);
  }

  async deletePartner(id) {
    await axios.delete(`/api/v1/partners/${id}`);
  }

  // Удаление партнеров
  async deletePartners(ids) {
    await axios.delete('/api/v1/partners', { data: { ids } });
  }

  async getPartner(id) {
    const response = await axios.get(`/api/v1/partners/${id}`);
    return response.data;
  }
}
