<template>
  <div class="fs full-screen">
    <div class="fs-content-wrapp">
      <img class="fs-logo" src="../../assets/images/svg/logo.svg" alt="">
      <div class="fs-content">
        <div class="fs-title">Интерактивная карта РЕХАУ</div>
        <div class="login-form">
          <label class="fs-pink" v-if="errorMessages.login">{{ errorMessages.login }}</label>
          <label class="fs-green" v-if="recoveryPromise">Если пользователь с таким email существует - вы скоро получите
            письмо</label>
          <label class="fs-label">Имя</label>
          <div class="fs-input">
            <input @keydown="onEnter($event, login)" v-model="username" />
          </div>
          <label class="fs-label">Пароль</label>
          <div class="fs-input">
            <PasswordInput>
              <input @keydown="onEnter($event, login)" type="password" v-model="password" />
            </PasswordInput>
          </div>
          <div class="fs-item">
            <label class="fs-pink" v-if="inviteRecoveryDialog" @click="recoveryDialog">
              Забыли имя пользователя?
            </label>
            <button class="btn btn-login btn-green" type="button" @click="login">
              Войти
              <span class="fs-icon">
                <img src="../../assets/images/svg/arrow-right.svg" alt="">
              </span>
            </button>
          </div>
          <div v-if="showRecoveryDialog">
            <label class="fs-label">Email</label>
            <div class="fs-input">
              <input @keydown="onEnter($event, recovery)" v-model="email" />
            </div>
            <label class="fs-label fs-error fs-pink" v-if="wrongEmail">Неправильный email</label>
            <button class="btn btn-email btn-green mt-4" type="button" @click="recovery">
              Выслать письмо для подтверждения
              <span class="fs-icon">
                <img src="../../assets/images/svg/arrow-right.svg" alt="">
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="fs-copy">© РЕХАУ 2021</div>
    </div>
  </div>
</template>

<script>
import PasswordInput from '../../components/PasswordInput.vue';

export default {
  name: 'Login',
  components: {
    PasswordInput
  },
  data() {
    return {
      username: '',
      password: '',
      email: '',
      inviteRecoveryDialog: true,
      showRecoveryDialog: false,

      recoveryPromise: false,
      wrongEmail: false,

      errorMessages: {
        login: null
      }
    }
  },
  methods: {
    async login() {
      this.errorMessages.login = null;
      const payload = {
        username: this.username,
        password: this.password
      };
      try {
        await this.$store.dispatch('auth/login', payload);
        await this.$store.dispatch('auth/fetchAuthUser');
        await this.$router.push({ name: 'partners-list' });
      } catch (error) {
        if ((error.status === 422 || error.status === 400) && error.data && error.data.detail) {
          this.wrongEmail = false;
          switch (error.data.detail.toString().toLowerCase()) {
            case 'login_bad_credentials': this.errorMessages.login = 'Неправильное имя пользователя или пароль. Пожалуйста, проверьте введенные данные и попытайтесь снова.'; break;
            case 'user_is_not_active': this.errorMessages.login = 'Пользователь неактивен. Обратитесь к администратору системы.'; break;
            default: break;
          }
        }
      }
    },
    onEnter($event, callback) {
      if ($event && $event.code === 'Enter') {
        callback();
      }
    },
    async recovery() {
      const payload = {
        email: this.email
      }
      try {
        await this.$store.dispatch('auth/forgotPassword', payload);
        this.recoveryPromise = true;
        this.showRecoveryDialog = false;
        this.errorMessages.login = null;
      } catch (error) {
        if (error.status === 422 || error.status === 400) {
          this.wrongEmail = true;
          this.errorMessages.login = null;
        }
      }
    },
    recoveryDialog() {
      this.showRecoveryDialog = true;
      this.inviteRecoveryDialog = false;
    }
  }
}
</script>

<style scoped>
.full-screen {
  padding-top: 200px;
  height: auto;
  display: block;
}

.fs-content-wrapp {
  position: relative;
  width: 460px;
  margin: auto;
  padding-bottom: 34px;
}

.fs-content {
  position: relative;
  padding: 30px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.fs-copy {
  position: absolute;
  bottom: 0;
  left: 0;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  color: #4E4E4E;
}

.fs-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 20px;
}

.fs-logo {
  width: 140px;
  margin-bottom: 30px;
}

.fs-error {
  font-size: 14px;
  line-height: 17px;
  color: #CF1322;
}

.fs-green {
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  color: #58BCAF;
}

.fs-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  padding: 10px;
  margin: 10px 0;
}

.fs-label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin: 5px 0;
}

.fs-pink {
  font-size: 16px;
  line-height: 24px;
  color: #DC143C;
}

.btn-login {
  margin: 20px 0;
}

.btn-email {
  width: 100%;
}

.fs-icon {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 16px;
}

.fs-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
