import mock from '@/fake-db/mock';
import Constants from '@/constants/Constants';

const socials = [
  {
    id: 1,
    name: 'Facebook'
  },
  {
    id: 2,
    name: 'VK'
  },
  {
    id: 3,
    name: 'Одноклассники'
  },
  {
    id: 4,
    name: 'Instagram'
  },
  {
    id: 5,
    name: 'Twitter'
  },
  {
    id: 6,
    name: 'Telegram'
  },
  {
    id: 7,
    name: 'WhatsApp'
  },
  {
    id: 8,
    name: 'Viber'
  },
  {
    id: 9,
    name: 'Youtube'
  }
];

mock.onGet('/api/v1/socials').reply((request) => new Promise(((resolve, reject) => {
  setTimeout(() => resolve([200, socials]), 200);
})));
