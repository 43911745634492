export default {
  sorting: {
    id: null,
    name: null,
    type: null,
    is_show_on_map: null,
    business_regions: null,
    curator: null,
    additional_curators: null
  },
  partnerTypes: [],
  partnerAttributes: []
}
