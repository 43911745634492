import axios from '@/axios';
import Service from './Service';

export default class FileUploadService extends Service {
    constructor(name = 'SocialsService', namespace = null, ...args) {
        super(name, namespace);
    }

  async uploadFile(file) {
    // eslint-disable-next-line func-names
    const fileData = new FormData();
    fileData.append('file_obj', file, file.name);
    return axios.post('/api/v1/upload_file', fileData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => response.data.id)
      .catch(err => {
        console.log(err);
        return null;
      })
  }
}
