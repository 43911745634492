export default {
    SET_GROUPS(state, groups) {
        state.groups = groups;
    },
    SET_PRODUCTS(state, products) {
      state.products = products.map(product => ({ ...product, selected: false }));
    },
    SET_ACTIVE_PRODUCT(state, { id, groupId }) {
      state.products.forEach(product => {
        if (product.product_group === groupId) {
          product.selected = product.id === id;
        }
      });
    },
    SET_GROUP_NAME(state, { id, newName }) {
      state.groups.find(group => group.id === id).name = newName;
    }
}
