import state from './DirectionState';
import mutations from './DirectionMutations';
import actions from './DirectionActions';
import getters from './DirectionGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
