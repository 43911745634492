import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import PartnerService from '@/services/PartnerService';
import FileUploadService from '@/services/FileUploadService';
import MessageService from './services/MessageService';
import DateTimeService from './services/DateTimeService';
import UserService from './services/UserService';
import SocialsService from './services/SocialsService';
import ProductService from './services/ProductService';
import DirectionService from './services/DirectionService';
import ObjectService from './services/ObjectService';
import CountryService from './services/CountryService';
import AddressService from './services/AddressService';
import RegionService from './services/RegionService';
import ImportDataService from './services/ImportDataService';
import ResponseInterceptor from './interceptors/ResponseInterceptor';
import App from './App.vue';
import router from './router';
import store from './store';
import 'external-svg-loader';

// mock
import './fake-db/index';
import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/scss/main.scss';

axios.defaults.withCredentials = true;
Vue.use(ElementUI, { locale });
Vue.use(VueAxios, axios);
Vue.use(new DateTimeService('DateTimeService', '$services'));
Vue.use(new MessageService('MessageService', '$services'));
Vue.use(new UserService('UserService', '$services'));
Vue.use(new SocialsService('SocialsService', '$services'));
Vue.use(new RegionService('RegionService', '$services'));
Vue.use(new DirectionService('DirectionService', '$services'));
Vue.use(new CountryService('CountryService', '$services'));
Vue.use(new ObjectService('ObjectService', '$services'));
Vue.use(new ProductService('ProductService', '$services'));
Vue.use(new AddressService('AddressService', '$services'));
Vue.use(new ProductService('ProductService', '$services'));
Vue.use(new PartnerService('PartnerService', '$services'));
Vue.use(new FileUploadService('FileUploadService', '$services'));
Vue.use(new ImportDataService('ImportDataService', '$services'));
Vue.use(ResponseInterceptor);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
