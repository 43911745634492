<template>
  <header class="header">
    <AddImportData v-if="isAuthUserAsAdmin" :visible="importDataDialog" @close="importDataDialog = false"
      @save="addSocial($event)" />
    <a class="logo">
      <img class="logo-img" src="../../assets/images/svg/logo.svg" alt="">
    </a>
    <ul class="nav-list">
      <li class="nav-list__item" :class="{ 'active': ['partners-list'].includes($route.name) }">
        <router-link :to="{ name: 'partners-list' }" class="nav-list__link">Партнеры</router-link>
      </li>
      <li class="nav-list__item" :class="{ 'active': ['facilities-list'].includes($route.name) }">
        <router-link :to="{ name: 'facilities-list' }" class="nav-list__link">Объекты</router-link>
      </li>
      <li class="nav-list__item" v-if="isAuthUserAsAdmin">
        <el-dropdown>
          <div class="flex items-center el-dropdown-link">
            <p class="nav-list__link">
              Справочники
            </p>
            <svg class="nav-list__drop-down" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.666016 0.666656L5.99935 5.99999L11.3327 0.666656H0.666016Z" />
            </svg>
          </div>
          <el-dropdown-menu class="sub-nav" slot="dropdown">
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'directions' }" class="nav-list__link">
                Направления
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'countries' }" class="nav-list__link">
                Страны
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'geo-regions' }" class="nav-list__link">
                Географические регионы
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'regions-list' }" class="nav-list__link">
                Бизнес-регионы
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'products' }" class="nav-list__link">
                Системы и продукты
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'objects' }" class="nav-list__link">
                Виды объектов
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'partner-types' }" class="nav-list__link">
                Типы партнеров
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'partner-attributes' }" class="nav-list__link">
                Признаки партнеров
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: 'socials' }" class="nav-list__link">
                Социальные сети
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="sub-nav__item">
              <router-link :to="{ name: '' }" class="nav-list__link">
                Реклама
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li class="nav-list__item" :class="{ 'active': ['users-list'].includes($route.name) }">
        <router-link :to="{ name: 'users-list' }" class="nav-list__link">Пользователи</router-link>
      </li>
      <li class="nav-list__item">
        <a :href="linkToMap" target="_blank" rel="noopener noreferrer" class="nav-list__link">
          Перейти к карте
        </a>
      </li>
      <li class="nav-list__item" v-if="isAuthUserAsAdmin">
        <button class="nav-list__link" @click="importDataDialog = true">
          Импорт данных
        </button>
      </li>
    </ul>

    <div class="actions">
      <div @click="showProfileMenu" class="profile">
        <div class="profile__avatar">
          <img class="profile__img" src="../../assets/images/svg/user.svg" alt="">
        </div>
        <div class="profile__title">{{ authUser ? authUser.username : '' }}</div>
        <ul v-if="showProfile" class="profile-popup" v-click-outside="closeProfileMenu">
          <li @click="toRoute('profile')" class="profile-popup__item">
            <p class="profile-popup__title">Профиль</p>
            <p class="profile-popup__edit">Изменить данные профиля</p>
          </li>
          <li @click="logout" class="profile-popup__item">
            <p class="profile-popup__title">Выйти</p>
            <p class="profile-popup__edit">Выйти из панели управления</p>
          </li>
        </ul>
      </div>
      <div class="settings">
        <div class="settings__box">
          <img class="settings__img" src="../../assets/images/svg/setting.svg" alt="">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import AddImportData from '@/components/AddImportData.vue';

export default {
  name: 'Header',
  components: {
    AddImportData
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      linkToMap: `${process.env.VUE_APP_MAP_LINK}`,
      showProfile: false,
      importDataDialog: false
    }
  },
  methods: {
    showProfileMenu() {
      this.showProfile = !this.showProfile;
    },
    closeProfileMenu(event) {
      if (!event.target.closest('.profile')) {
        this.showProfile = false;
      }
    },
    toRoute(route) {
      this.$router.push({ name: route });
    },
    async logout() {
      await this.$store.dispatch('auth/logout');
      await this.$router.push({ name: 'login' });
    },
    importData() {
    }
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/getAuthUser'];
    },
    isAuthUserAsAdmin() {
      const adminRoleId = this.$store.getters['user/getAdminRoleId'];
      return this.authUser.role_id === adminRoleId;
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #BFBFBF;
  height: 83px;
}

.logo {
  margin: 11px 0 0 40px;
  align-self: start;
}

.logo-img {
  width: 140px;
}

.nav-list {
  margin: 0 20px 0 34px;
}

.actions {
  display: flex;
  align-items: center;
  margin: 0 40px 0 auto;
  flex-shrink: 0;
}
</style>
