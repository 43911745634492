import axios from '@/axios';

export default {
    async fetchCountries({ commit }) {
      const params = { page_size: 10000, ordering: 'name' };
      const response = await axios.get('/api/v1/countries', { params });
      commit('SET_COUNTRIES', response.data.results);
    },
    setCountries({ commit }, payload) {
      commit('SET_COUNTRIES', payload);
    }
}
