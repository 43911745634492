import Vue from 'vue';
import VueRouter from 'vue-router';
import $store from '@/store/index';

Vue.use(VueRouter);

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: '',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/',
        redirect: '/partners'
      },
      {
        path: '/users',
        name: 'users-list',
        component: () => import('@/pages/user/Users.vue')
      },
      {
        path: '/users/create',
        name: 'user-create',
        component: () => import('@/pages/user/UserCreate.vue')
      },
      {
        path: '/users/:userId',
        name: 'user-edit',
        component: () => import('@/pages/user/UserEdit.vue')
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/user/Profile.vue')
      },
      {
        path: '/objects',
        name: 'facilities-list',
        component: () => import('@/pages/facility/Facilities.vue')
      },
      {
        path: '/objects/create',
        name: 'facility-create',
        component: () => import('@/pages/facility/FacilityCreate.vue')
      },
      {
        path: '/objects/edit/:id',
        name: 'facility-edit',
        component: () => import('@/pages/facility/FacilityCreate.vue')
      },
      {
        path: '/directions',
        name: 'directions',
        component: () => import('@/pages/reference/ReferenceTable.vue')
      },
      {
        path: '/references',
        name: 'objects',
        component: () => import('@/pages/reference/ReferenceTable.vue')
      },
      {
        path: '/partner-types',
        name: 'partner-types',
        component: () => import('@/pages/reference/ReferenceTable.vue')
      },
      {
        path: '/partner-attributes',
        name: 'partner-attributes',
        component: () => import('@/pages/reference/ReferenceTable.vue')
      },
      {
        path: '/geo-regions',
        name: 'geo-regions',
        component: () => import('@/pages/reference/GeoRegions.vue')
      },
      {
        path: '/countries',
        name: 'countries',
        component: () => import('@/pages/reference/ReferenceTable.vue')
      },
      {
        path: '/socials',
        name: 'socials',
        component: () => import('@/pages/reference/ReferenceTable.vue')
      },
      {
        path: '/regions',
        name: 'regions-list',
        component: () => import('@/pages/region/Regions.vue')
      },
      {
        path: '/regions/create',
        name: 'region-create',
        component: () => import('@/pages/region/RegionCreate.vue')
      },
      {
        path: '/regions/edit/:id',
        name: 'region-edit',
        component: () => import('@/pages/region/RegionCreate.vue')
      },
      {
        path: '/partners',
        name: 'partners-list',
        component: () => import('@/pages/partner/Partners.vue')
      },
      {
        path: '/partners/create',
        name: 'partner-create',
        component: () => import('@/pages/partner/PartnerCreate.vue')
      },
      {
        path: '/partners/edit/:id',
        name: 'partner-edit',
        component: () => import('@/pages/partner/PartnerCreate.vue')
      },
      {
        path: '/products',
        name: 'products',
        component: () => import('@/pages/systems/Systems.vue')
      }
    ]
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/FullPage.vue'),
    children: [
      // =============================================================================
      // PAGES
      // =============================================================================

      {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/auth/Login.vue'),
        meta: {
          rule: 'editor'
        }
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/pages/auth/ResetPassword.vue'),
        meta: {
          rule: 'editor'
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const routesGuard = (to, from, next, authUser, isAdmin, isRegional) => {
  if (!['login', 'reset-password'].includes(to.name) && !authUser) {
    next({ name: 'login' });
  } else if (to.name === 'login' && authUser) {
    next({ name: 'partners-list' });
  } else if (to.name === 'user-edit' && authUser && !isAdmin && parseInt(to.params.userId, 10) !== parseInt(authUser.id, 10)) {
    next({ name: 'partners-list' });
  } else if (to.name === 'user-create' && authUser && !isAdmin) {
    next({ name: 'partners-list' });
  } else if ((to.name === 'products'
    || to.name === 'directions'
    || to.name === 'geo-regions'
    || to.name === 'countries'
    || to.name === 'socials'
    || to.name === 'partner-types'
    || to.name === 'partner-attributes'
    || to.name === 'objects'
    || to.name === 'region-create'
    || to.name === 'region-edit'
    || to.name === 'regions-list')
    && authUser && !isAdmin) {
    next({ name: 'partners-list' });
  } else {
    next();
  }
};

router.beforeEach(async (to, from, next) => {
  let authUser = $store.getters['auth/getAuthUser'];
  if (!authUser) {
    await $store.dispatch('auth/fetchAuthUser');
    authUser = $store.getters['auth/getAuthUser'];
  }
  let adminRoleId = $store.getters['user/getAdminRoleId'];
  let regionalRoleId = $store.getters['user/getRegionalRoleId'];
  if (typeof adminRoleId !== 'number' || typeof regionalRoleId !== 'number') {
    await $store.dispatch('user/fetchRoles');
    adminRoleId = $store.getters['user/getAdminRoleId'];
    regionalRoleId = $store.getters['user/getRegionalRoleId'];
  }
  routesGuard(to,
    from,
    next,
    authUser,
    authUser ? authUser.role_id === adminRoleId : false,
    authUser ? authUser.role_id === regionalRoleId : false);
});

export default router;
