export default {
  emailIsValid(email) {
    const reg = /^[\w-.]+@[\w-.]+\.[\w-.]+$/;
    return reg.test(email);
  },
  passwordIsValid(password) {
    return this.validatePassword(password).isValid;
  },
  phoneNumberIsValid(tel) {
    return true;
  },
  yearIsValid(year) {
    const reg = /^\d{4}$/;
    return reg.test(year);
  },
  validatePassword(password) {
    const regUppercaseCharacters = /[A-Z]/;
    const regSymbols = /[^a-zA-Z\d\s:]/;
    const regCharacters = /[a-y]/;
    const regNumbers = /\d/;
    let errorMessage = null;

    if (typeof password === 'string') {
      if (password.trim().length < 8) {
        errorMessage = 'Пароль должен содержать не менее 8 символов';
      } else if (!regCharacters.test(password)) {
        errorMessage = 'Пароль должен содержать не менее 1 прописной буквы';
      } else if (!regUppercaseCharacters.test(password)) {
        errorMessage = 'Пароль должен содержать не менее 1 заглавной буквы';
      } else if (!regNumbers.test(password)) {
        errorMessage = 'Пароль должен содержать не менее 1 цифры';
      } else if (!regSymbols.test(password)) {
        errorMessage = 'Пароль должен содержать не менее 1 специального символа';
      }
    } else {
      errorMessage = 'Не верный формат данных';
    }

    return {
      isValid: !errorMessage,
      errorMessage
    };
  },
  imageIsValid(file) {
    let errorMessage = null;
    const sizeLimit = 10240000; // 10Mb
    const validExtensions = ['png', 'jpg', 'jpeg', 'svg'];
    const { name, size } = file;
    const extension = name.split('.').pop();
    if (!validExtensions.includes(extension)) {
      errorMessage = `Неверный формат изображения ${name}`;
    } else if (size > sizeLimit) {
      errorMessage = `Размер файла ${name} превышает 10 Mb`;
    }

    return {
      isValid: !errorMessage,
      errorMessage
    };
  }
}
