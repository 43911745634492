import axios from '@/axios';

export default {
  setGroups({ commit }, payload) {
    commit('SET_GROUPS', payload);
  },
  setProducts({ commit }, payload) {
    commit('SET_PRODUCTS', payload);
  },
  setActiveProduct({ commit }, payload) {
    commit('SET_ACTIVE_PRODUCT', payload);
  },
  setGroupName({ commit }, payload) {
    commit('SET_GROUP_NAME', payload)
  },
  async fetchGroups({ commit }) {
    const response = await axios.get('/api/v1/product_groups');
    commit('SET_GROUPS', response.data);
  },
  async fetchProducts({ commit }) {
    const params = { page_size: 10000 };
    const response = await axios.get('/api/v1/products', { params });
    commit('SET_PRODUCTS', response.data.results);
  }
}
