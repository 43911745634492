<template>
  <ul v-if="showPopup" class="facility-popup" :class="{ 'right': rightPosition }">
    <li class="facility-popup__item" @click="onEdit">
      <div class="facility-popup__link">Редактировать</div>
    </li>
    <li class="facility-popup__item" @click="onDelete">
      <div class="facility-popup__link">Удалить</div>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'FacilityPopup',
    props: {
      facilityId: Number,
      showPopup: Boolean,
      rightPosition: Boolean
    },
    data() {
      return {
      }
    },
    methods: {
      onEdit() {
        this.$emit('onEdit', this.facilityId)
      },
      onDelete() {
        this.$emit('onDelete', this.facilityId)
      }
    }
  }
</script>

<style scoped>
  .right {
    right: 0;
  }

  .facility-popup {
    position: absolute;
    top: 0;
    width: 280px;
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    transition: all .3s ease-in-out;
    z-index: 1;
  }

  .facility-popup__link {
    display: block;
    font-size: 16px;
    line-height: 19px;
    padding: 12px 10px;
    transition: all .3s ease-in-out;
  }

  .facility-popup__link:hover {
    background: #E5E5E5;
  }
</style>
