import axios from '@/axios';
import Service from './Service';

export default class ProductService extends Service {
    constructor(name = 'ProductService', namespace = null, ...args) {
        super(name, namespace);
    }

    async addGroup(dto) {
        const response = await axios.post('/api/v1/product_groups', dto);
        return response.data;
    }

    async addProduct(dto) {
      await axios.post('/api/v1/products', dto);
    }

    async getGroups(params) {
      const response = await axios.get('/api/v1/product_groups', { params });
      return response.data;
    }

    async getProducts() {
      const params = { page_size: 10000 };
      const response = await axios.get('/api/v1/products', { params });
      return response.data;
    }

    async getGroup(id) {
      const response = await axios.get(`/api/v1/product_groups/${id}`);
      return response.data;
    }

    async getProduct(id) {
      const response = await axios.get(`/api/v1/products/${id}`);
      return response.data;
    }

    async updateGroup(item, id) {
        return axios.patch(`/api/v1/product_groups/${id}`, item);
    }

    async updateProduct(item, id) {
      return axios.patch(`/api/v1/products/${id}`, item);
    }

    async deleteGroup(id) {
        await axios.delete(`/api/v1/product_groups/${id}`)
    }

    async deleteProduct(id) {
      await axios.delete(`/api/v1/products/${id}`)
    }
}
