import axios from '@/axios';
import Service from './Service';

const qs = require('qs');

export default class RegionService extends Service {
    constructor(name = 'RegionService', namespace = null, ...args) {
        super(name, namespace);
    }

  async addGeoRegion(dto) {
    await axios.post('/api/v1/geographic_regions', dto);
  }

  async addBusinessRegion(dto) {
      await axios.post('/api/v1/business_regions', dto);
  }

  async getGeoRegions(params) {
    const response = await axios.get('/api/v1/geographic_regions', { params });
    return response.data;
  }

  async getBusinessRegions(params) {
    const response = await axios.get('/api/v1/business_regions', { params });
    return response.data;
  }

  async getBusinessRegion(id) {
    const response = await axios.get(`/api/v1/business_regions/${id}`);
    return response.data;
  }

  async updateGeoRegion(region, id) {
      return axios.patch(`/api/v1/geographic_regions/${id}`, region);
  }

  async updateBusinessRegion(region, id) {
    return axios.patch(`/api/v1/business_regions/${id}`, region);
  }

  async deleteBusinessRegion(id) {
    await axios.delete(`/api/v1/business_regions/${id}`)
  }

  async deleteGeoRegion(id) {
    await axios.delete(`/api/v1/geographic_regions/${id}`)
  }
}
