<template>
  <div class="g-container">
    <div ref="active_popup">
      <Popup v-if="isAuthUserAsAdmin || isAuthUserRegional"
             v-click-outside="closePopup"
             :rightPosition="popup.rightPosition"
             :user-id="popup.regionId"
             :show-popup="popup.show"
             @onEdit="editRegion(popup.regionId)"
             @onDelete="confirmDelete"
      ></Popup>
    </div>
    <ConfirmPopup
      v-if="isAuthUserAsAdmin || isAuthUserRegional"
      :show="popup.confirm"
      :text="`Удалить бизнес-регион ${popup.regionName}?`"
      @close="popup.confirm = false"
      @confirm="deleteRegion(popup.regionId)"
    ></ConfirmPopup>
    <div class="users-page">
      <h2 class="users-page__title">Бизнес-регионы</h2>
      <div class="flex justify-between items-start">
        <button v-if="isAuthUserAsAdmin || isAuthUserRegional" class="btn btn-green" @click="createRegion">Добавить бизнес-регион</button>
        <Search :is-not-found="isNotFound" @query="changeQueryString($event)" place-holder="Поиск"></Search>
      </div>
      <div class="table-wrapper">
        <table class="users-table" v-if="regions.length > 0">
          <thead class="users-table__head">
            <tr>
              <th class="table-name" @click="order = changedOrderState('name', '-name', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Бизнес-регион</span>
                  <img :class="{'users-table-rotate__icon': order === '-name', 'opacity-0': !['name', '-name'].includes(order)}"
                       class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th class="table-desc" @click="order = changedOrderState('direction_name', '-direction_name', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Направление</span>
                  <img :class="{'users-table-rotate__icon': order === '-direction_name', 'opacity-0': !['direction_name', '-direction_name'].includes(order)}"
                       class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(region, index) in regions" :key="index">
              <td class="table-name" @contextmenu.prevent="showPopup(region.id, `name${region.id}`)">
                <div class="users-box" :ref="`name${region.id}`">
                  <span class="users-table__desc truncate">{{ region.name }}</span>
                </div>
              </td>
              <td class="table-desc" @contextmenu.prevent="showPopup(region.id, `direction${region.id}`)">
                <div class="users-box" :ref="`direction${region.id}`">
                  <span class="users-table__desc truncate">
                    {{ region.direction_name }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :current-page="page"
        :total-pages="countPages"
        :per-page="regions.length"
        :is-next-users-page="isNextPage"
        :is-previous-users-page="isPreviousPage"
        @onNext="changeCurrentPage(page + 1)"
        @onPrevious="changeCurrentPage(page - 1)"
      ></Pagination>
    </div>
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside';
  import Pagination from '@/components/Pagination.vue';
  import Search from '@/components/Search.vue';
  import Popup from '@/components/RegionsPopup.vue';
  import ConfirmPopup from '@/components/ConfirmPopup.vue';

  export default {
    name: 'Regions',
    directives: {
        ClickOutside
    },
    components: {
      Search,
      Pagination,
      Popup,
      ConfirmPopup
    },
    data() {
      return {
        queryString: '',
        isNotFound: false,
        popup: {
            show: false,
            confirm: false,
            regionId: null,
            regionName: '',
            rightPosition: false
        },
        isNextPage: false,
        isPreviousPage: false,
        countPages: 1,
        page: 0,
        perPage: 10,
        regions: [],
        order: null
      }
    },
    async created() {
      await this.fetchBusinessRegions();
    },
    watch: {
      async order() {
        await this.fetchBusinessRegions();
      }
    },
    methods: {
      closePopup() {
        this.popup.show = false;
      },
      changedOrderState(ascName, descName, currentState) {
        if (currentState === null || ![ascName, descName].includes(currentState)) {
          return ascName;
        }
        if (currentState === ascName) {
          return descName;
        }
        return null;
      },
      async deleteRegion(id) {
        this.popup.confirm = false;
        try {
          await this.$services.RegionService.deleteBusinessRegion(id);
          this.$services.MessageService.success('Данные успешно удалены');
          await this.fetchBusinessRegions();
        } catch (err) {
          console.log(err);
          this.$services.MessageService.error('Удаление невозможно – бизнес-регион используется в других объектах данных');
        }
      },
      createRegion() {
        this.$router.push({ name: 'region-create' });
      },
      confirmDelete() {
        this.closePopup();
        this.popup.confirm = true;
      },
      changeQueryString(string) {
        if (this.queryString !== string) {
          this.queryString = string;
        }
        if (!string) {
          this.queryString = '';
        }
        if (!this.queryString || this.queryString.length > 2) {
          this.page = 0;
          this.fetchBusinessRegions();
        }
      },
      async fetchBusinessRegions() {
          let params = {
              page: this.page,
              page_size: this.perPage
          };
          if (this.queryString.length === 0 && this.order === null) {
              params = {
                  ...params
              };
          } else if (this.queryString.length === 0 && this.order !== null) {
              params = {
                  ...params,
                  ordering: this.order
              };
          } else if (this.queryString.length > 0 && this.order === null) {
              params = {
                  ...params,
                  search_query: this.queryString
              };
          } else if (this.queryString.length > 0 && this.order !== null) {
              params = {
                  ...params,
                  search_query: this.queryString,
                  ordering: this.order
              };
          }
          const regionsData = await this.$services.RegionService.getBusinessRegions(params);
          this.isNextPage = !!regionsData.next;
          this.isPreviousPage = !!regionsData.previous;
          this.countPages = Math.ceil(regionsData.count / this.perPage);
          this.regions = regionsData.results;
      },
      async changeCurrentPage(page) {
          this.page = page;
          await this.fetchBusinessRegions();
      },
      showPopup(regionId, ref) {
        const element = this.$refs[ref][0];
          if (element) {
            element.appendChild(this.$refs['active_popup']);
            this.popup.regionId = regionId;
            this.popup.regionName = this.regions.find(region => region.id === regionId).name;
            this.popup.show = true;
          }
      },
      editRegion(regionId) {
        this.$router.push({
            name: 'region-edit',
            params: { id: regionId }
        });
      }
      // businessRegionsString(ids) {
      //     const regionsMap = this.$store.getters['business_region/getRegionsMap'];
      //     const regions = Array.isArray(ids) && regionsMap instanceof Map && regionsMap.size > 0 ? ids.map(id => regionsMap.get(id)) : [];
      //     return regions.map(region => region.name).join();
      // }
    },
    computed: {
        isAuthUserAsAdmin() {
            const adminRoleId = this.$store.getters['user/getAdminRoleId'];
            const authUser = this.$store.getters['auth/getAuthUser'];
            return authUser.role_id === adminRoleId;
        },
        isAuthUserRegional() {
          const regionalRoleId = this.$store.getters['user/getRegionalRoleId'];
          const authUser = this.$store.getters['auth/getAuthUser'];
          return authUser.role_id === regionalRoleId;
        }
    }
  }
</script>

<style scoped>
.table-id {
  width: 5%;
}

.table-name {
  width: 20%;
}

.table-desc {
  width: 75%;
}

.max-width-100 {
  max-width: 100px;
}
</style>
