<template>
<div class="map">
  <yandex-map
    :settings="settings"
    :options="options"
    :controls="[]"
    :coords="coords"
    :zoom="12"
    @click="!loading && registerClick($event)"
    @map-was-initialized="map = $event">
    <ymap-marker
      v-if="point"
      :coords="coords"
      marker-id="1"
      :icon="icon"
      :options="markerOptions"
      :properties="{ data: mapDirectionsToColors }"></ymap-marker>
  </yandex-map>
</div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import colors from '@/helpers/DirectionColors';

export default {
  name: 'MiniMap',
  props: {
    coords: Array,
    directions: Array,
    point: Boolean,
    marker: String
  },
  components: {
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      map: null,
      settings: {
        apiKey: process.env.VUE_APP_MAP_KEY,
        lang: 'ru_RU'
      },
      options: {
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true
      },
      markerOptions: {
        iconLayout: 'default#pieChart',
        iconPieChartRadius: 35,
        iconPieChartCoreRadius: 28,
        iconPieChartStrokeWidth: 0
      }
    }
  },
  methods: {
    registerClick(click) {
      if (!this.loading) {
        const coords = click.get('coords');
        this.$emit('setPoint', [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])]);
      }
    }
  },
  computed: {
    loading() {
      return this.$store.getters['map/getLoading']
    },
    mapDirectionsToColors() {
      return this.directions.map(direction => ({ color: colors[direction.code.toLowerCase()], weight: 1 }));
    },
    icon() {
      const color = this.mapDirectionsToColors.length ? this.mapDirectionsToColors[0].color : '';
      return {
        content: `<svg data-src="${this.marker}" xmlns="http://www.w3.org/2000/svg" fill="${color}" class="map-logo"></svg>`
      }
    }
  }
};
</script>

<style scoped>
.ymap-container {
  width: 100%;
  height: 618px;
}
</style>
