import axios from '@/axios';

export default {
  async fetchPartnerTypes({ commit }) {
    const params = { page_size: 10000, ordering: 'name' }
    const response = await axios.get('/api/v1/partner_types', { params });
    commit('SET_PARTNER_TYPES', response.data.results);
  },
  async fetchPartnerAttributes({ commit }) {
    const params = { page_size: 10000, ordering: 'name' }
    const response = await axios.get('/api/v1/partner_attributes', { params });
    commit('SET_PARTNER_ATTRIBUTES', response.data.results);
  },
  setPartnerTypes({ commit }, types) {
    commit('SET_PARTNER_TYPES', types);
  },
  setPartnerAttributes({ commit }, attrs) {
    commit('SET_PARTNER_ATTRIBUTES', attrs);
  }
}
