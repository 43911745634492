<template>
  <div>
    <div class="form-item">
      <label class="form-label">Бизнес-регион*</label>
      <Select ref="select" :disabled="disabled" v-model="business_regions" multiple :filterable="true" collapse-tags
        placeholder="Выберите или введите название" class="form-select"
        :class="{ 'error': business_regions.length === 0 && !disabled, 'opacity-50': disabled }">
        <li class="el-select-dropdown__item" @click="selectAllBusinessRegions">
          <p>
            <input class="form-input-check" type="checkbox"
              :checked="business_regions.length > 0 && business_regions.length === businessRegions.length">
            <label class="form-label form-label-check-white">Все</label>
          </p>
        </li>
        <Option v-for="region in businessRegions" :key="region.id" :label="region.name" :value="region.id">
          <p>
            <input class="form-input-check" type="checkbox" :checked="businessRegionIsSelected(region.id)">
            <label class="form-label form-label-check-white">{{ region.name }}</label>
          </p>
        </Option>
      </Select>
      <slot name="error"></slot>
    </div>
    <div class="form-item" v-if="showSelected && selectedBusinessRegions.length > 0">
      <div class="region-title">
        <p class="region-list__title">Название бизнес-региона</p>
        <img @click="changeBusinessRegionsSort" class="region-list__icon" src="@/assets/images/svg/down.svg" alt=""
          :class="{ 'region-list__icon-rotate': businessRegionsSort === 'desc' }">
      </div>
      <ul class="region-list" :class="{ 'opacity-50': disabled }">
        <li class="region-list__item" v-for="selectedRegion in selectedBusinessRegions" v-bind:key="selectedRegion.id">
          <span>{{ selectedRegion.name }}</span>
          <div v-if="!disabled" class="cursor-pointer" @click="removeSelectedRegion(selectedRegion.id)">
            <img class="region-list__close-icon" src="@/assets/images/svg/close.svg" alt="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  name: 'BusinessRegionsSelector',
  components: {
    Select,
    Option
  },
  props: {
    value: Array,
    showSelected: Boolean,
    disabled: Boolean,
    selectAll: Boolean
  },
  data() {
    return {
      business_regions: this.value,
      businessRegionsSort: 'asc'
    }
  },
  watch: {
    business_regions(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.business_regions = [];
      this.business_regions = newVal;
    }
  },
  async mounted() {
    // workaround
    this.openClose();
    await this.$store.dispatch('business_region/fetchRegions');
  },
  methods: {
    async selectAllBusinessRegions() {
      let regions = this.$store.getters['business_region/getRegions'];
      if (regions.length < 1) {
        await this.$store.dispatch('business_region/fetchRegions');
      }
      regions = this.$store.getters['business_region/getRegions'];
      const allRegions = Array.isArray(regions) && regions.length > 0 ? regions.filter(region => !!region).map(region => region.id) : [];
      if (this.business_regions.length < allRegions.length) {
        this.business_regions = allRegions;
      } else {
        this.business_regions = [];
      }
    },
    async clearBusinessRegions() {
      this.business_regions = [];
    },
    changeBusinessRegionsSort() {
      this.businessRegionsSort = this.businessRegionsSort === 'asc' ? 'desc' : 'asc';
    },
    removeSelectedRegion(id) {
      this.business_regions = this.business_regions ? this.business_regions.filter(regionId => regionId !== id) : this.business_regions;
    },
    clearSelectedRegions() {
      this.business_regions = [];
    },
    businessRegionIsSelected(id) {
      return this.business_regions.includes(id);
    },
    // workaround
    openClose() {
      const select = this.$refs.select;
      if (select && select.$el) {
        const button = select.$el.querySelector('.el-input__suffix');
        button.addEventListener('click', (event) => {
          event.stopImmediatePropagation();
          const isOpened = !!select.$el.querySelector('.el-input.el-input--suffix.is-focus');
          if (isOpened) {
            this.$refs.select.blur();
          } else {
            this.$refs.select.focus();
          }
        });
      }
    }
  },
  computed: {
    businessRegions() {
      return this.$store.getters['business_region/getRegions'];
    },
    selectedBusinessRegions() {
      const regionsMap = this.$store.getters['business_region/getRegionsMap'];
      const regions = regionsMap instanceof Map && regionsMap.size > 0 ? this.business_regions.map(id => regionsMap.get(id)).filter(region => region && region.name) : [];
      return regions.sort((a, b) => {
        if (a.name > b.name) {
          return this.businessRegionsSort === 'asc' ? 1 : -1;
        }
        return this.businessRegionsSort === 'asc' ? -1 : 1;
      });
    }
  }
}
</script>

<style scoped>
/* workaround */
.el-input__suffix {
  pointer-events: all;
}

.form-item {
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.message-error {
  color: #DC143C;
  font-size: 14px;
  line-height: 17px;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper {
  display: flex;
  justify-content: space-between;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.form-label-check-white {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-check-white::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.form-label-check-white::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: url("../assets/images/svg/checked-black.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.form-input-check[type="checkbox"]:checked+.form-label-check::after,
.form-input-check[type="checkbox"]:checked+.form-label-check-white::after {
  opacity: 1;
}

.form-input-check {
  display: none;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.el-select .el-input__suffix {
  background: #000000;
}

.el-select .el-input .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-icon-arrow-up:before {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-input__prefix,
.el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__inner {
  padding-left: 10px;
  padding-right: 0;
  border-radius: 0;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #000000;
}

.el-select .el-input__inner:focus {
  border-color: #000000;
}

.el-select-dropdown__list {
  padding: 0;
}

.el-select-dropdown__item {
  padding: 10px;
  height: auto;
  color: #000000;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #58BCAF;
  color: #FFFFFF;
}

.el-select-dropdown__item:hover+.el-select-dropdown__item {
  background: #FFFFFF;
}

.region-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.region-list {
  border-top: 2px solid #000000;
}

.region-list__title {
  display: flex;
}

.region-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px;
  font-size: 18px;
  line-height: 24px;
  transition: all .3s ease-in-out;
  background: #FFFFFF;
}

.region-list__item:hover {
  background: #E5E5E5;
}

.region-list__close-icon {
  width: 14px;
  height: 14px;
}

.region-list__icon {
  width: 9px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: 3px;
}

.region-list__icon-rotate {
  transform: rotate(180deg);
}
</style>
