<template>
  <div class="pagination">
    <span>Страница</span>
    <ol class="pagination-list">
      <li class="pagination-list__item active">
        <div class="pagination-list__link">{{ currentPage + 1 }}</div>
      </li>
      <li class="pagination-list__item" @click="onPrevious">
        <div :class="{ 'pagination-arrow--disabled': !isPreviousUsersPage || currentPage <= 0 }"
          class="pagination-arrow pagination-arrow-left"></div>
      </li>
      <li class="pagination-list__item" @click="onNext">
        <div :class="{ 'pagination-arrow--disabled': !isNextUsersPage || currentPage + 1 >= totalPages }"
          class="pagination-arrow pagination-arrow-right"></div>
      </li>
    </ol>
    <p class="pagination-count">из {{ totalPages }}</p>
    <p v-if="perPage" class="pagination__per-page">Элементов на странице: {{ perPage }}</p>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: Number,
    perPage: Number,
    totalPages: Number,
    isNextUsersPage: Boolean,
    isPreviousUsersPage: Boolean
  },
  data() {
    return {}
  },
  methods: {
    onNext() {
      if ((this.currentPage + 1) < this.totalPages) {
        this.$emit('onNext', this.currentPage + 1);
      }
    },
    onPrevious() {
      if (this.currentPage > 0) {
        this.$emit('onPrevious', this.currentPage - 1);
      }
    }
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pagination-list {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.pagination-list__item {
  padding: 7px;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-list__item.active {
  background: #E5E5E5;
}

.pagination-list__link {
  font-size: 18px;
  line-height: 24px;
}

.pagination-arrow {
  width: 10px;
  height: 10px;
  border-top: 2px solid #DC143C;
  border-right: 2px solid #DC143C;
  /*margin-right: 60px;*/
}

.pagination-arrow--disabled {
  border-color: #BFBFBF;
}

.pagination-arrow-left {
  transform: rotate(-135deg);
}

.pagination-arrow-right {
  transform: rotate(45deg);
}

.pagination-count {
  font-size: 18px;
  line-height: 24px;
}

.pagination__per-page {
  width: 100%;
}
</style>
