export default {
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_PER_PAGE(state, perPage) {
        state.perPage = perPage;
    },
    SET_SORTING(state, sorting) {
      state.sorting = sorting;
    },
    SET_FACILITY_TYPES(state, types) {
      state.facilityTypes = types;
    }
}
