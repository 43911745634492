import axios from '@/axios';

export default {
  async fetchFacilityTypes({ commit, state }) {
    if (state.facilityTypes.length === 0) {
      const params = { page_size: 10000, ordering: 'name' }
      const response = await axios.get('/api/v1/object_types', { params });
      commit('SET_FACILITY_TYPES', response.data.results);
    }
  },
  setFacilityTypes({ commit }, types) {
      commit('SET_FACILITY_TYPES', types);
  }
}
