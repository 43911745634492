<template>
  <div class="g-container">
    <div ref="active_popup">
      <Popup v-if="isAuthUserAsAdmin"
         v-click-outside="closePopup"
         :rightPosition="popup.rightPosition"
         :user-id="popup.userId"
         :show-popup="popup.show"
         @onEdit="editUser"
         @onDelete="deleteUser"
      ></Popup>
    </div>
    <div class="users-page">
      <h2 class="users-page__title">Пользователи</h2>
      <div class="flex justify-between items-start">
        <button class="btn btn-green" :class="{'opacity-0': !isAuthUserAsAdmin}" @click="createUser">Новый пользователь</button>
        <Search @query="changeQueryString" place-holder="Поиск пользователя" class="search"></Search>
      </div>
      <div v-if="users.length > 0" class="table-wrapper">
        <table class="users-table">
          <thead class="users-table__head">
          <tr>
            <th @click="order = changedOrderState('username', '-username', order)">
              <div class="users-table__box">
                <span class="users-table__title">Имя</span>
                <img :class="{'users-table-rotate__icon': order === '-username', 'opacity-0': !['username', '-username'].includes(order)}"
                     class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
              </div>
            </th>
            <th @click="order = changedOrderState('full_name', '-full_name', order)">
              <div class="users-table__box">
                <span class="users-table__title">Полное имя</span>
                <img :class="{'users-table-rotate__icon': order === '-full_name', 'opacity-0': !['full_name', '-full_name'].includes(order)}"
                     class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
              </div>
            </th>
            <th @click="order = changedOrderState('role', '-role', order)">
              <div class="users-table__box">
                <span class="users-table__title">Роль пользователя</span>
                <img :class="{'users-table-rotate__icon': order === '-role', 'opacity-0': !['role', '-role'].includes(order)}"
                     class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
              </div>
            </th>
            <th @click="order = changedOrderState('email', '-email', order)">
              <div class="users-table__box">
                <span class="users-table__title">Электронная почта</span>
                <img :class="{'users-table-rotate__icon': order === '-email', 'opacity-0': !['email', '-email'].includes(order)}"
                     class="users-table__icon" src="../../assets/images/svg/down.svg" alt="">
              </div>
            </th>
            <th @click="order = changedOrderState('direction', '-direction', order)">
              <div class="users-table__box">
                <span class="users-table__title">Направление</span>
                <img :class="{'users-table-rotate__icon': order === '-direction', 'opacity-0': !['direction', '-direction'].includes(order)}"
                     class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
              </div>
            </th>
            <th @click="order = changedOrderState('business_region', '-business_region', order)">
              <div class="users-table__box">
                <span class="users-table__title">Бизнес регион</span>
                <img :class="{'users-table-rotate__icon': order === '-business_region', 'opacity-0': !['business_region', '-business_region'].includes(order)}"
                     class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
              </div>
            </th>
            <th @click="order = changedOrderState('is_active', '-is_active', order)">
              <div class="users-table__box">
                <span class="users-table__title">Активный</span>
                <img :class="{'users-table-rotate__icon': order === '-is_active', 'opacity-0': !['is_active', '-is_active'].includes(order)}"
                     class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(user, index) in users" :key="index">
            <td @contextmenu.prevent="showPopup(user.id, `username${user.id}`)">
              <div class="users-box" :ref="`username${user.id}`">
                <span class="users-table__desc truncate">{{ user.username }}</span>
              </div>
            </td>
            <td @contextmenu.prevent="showPopup(user.id, `fullName${user.id}`)">
              <div class="users-box" :ref="`fullName${user.id}`">
                <span class="users-table__desc truncate">{{ user.full_name }}</span>
              </div>
            </td>
            <td @contextmenu.prevent="showPopup(user.id, `role${user.id}`)">
              <div class="users-box" :ref="`role${user.id}`">
                <span class="users-table__desc truncate">{{ getRole(user.role_id) }}</span>
              </div>
            </td>
            <td @contextmenu.prevent="showPopup(user.id, `email${user.id}`)">
              <div class="users-box" :ref="`email${user.id}`">
                <span class="users-table__desc truncate">{{ user.email }}</span>
              </div>
            </td>
            <td @contextmenu.prevent="showPopup(user.id, `direction${user.id}`)">
              <div class="users-box" :ref="`direction${user.id}`">
                <span class="users-table__desc truncate">{{ isAdminOrCallCenterEmployee(user.role_id) ? 'Все' : businessDirectionStrings(user.business_regions) }}</span>
              </div>
            </td>
            <td @contextmenu.prevent="showPopup(user.id, `regions${user.id}`)">
              <div class="users-box" :ref="`regions${user.id}`">
                <span class="users-table__desc truncate">{{ isAdminOrCallCenterEmployee(user.role_id) ? 'Все' : businessRegionsStrings(user.business_regions) }}</span>
              </div>
            </td>
            <td @contextmenu.prevent="showPopup(user.id, `active${user.id}`, true)">
              <div class="users-box" :ref="`active${user.id}`">
                <span v-if="user.is_active" class="users-table__desc users-table__desc--green">Да</span>
                <span v-else class="users-table__desc users-table__desc--green">Нет</span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="users.length > 0">
        <Pagination
          :current-page="page"
          :total-pages="countPages"
          :per-page="users.length"
          :is-next-users-page="isNextPage"
          :is-previous-users-page="isPreviousPage"
          @onNext="changeCurrentPage"
          @onPrevious="changeCurrentPage"
        ></Pagination>
      </div>
    </div>
    <div class="users-not" v-if="users.length === 0">
      <div v-if="queryString">
        <p class="users-not__title">К сожалению, по запросу «{{ queryString }}» ничего не найдено.</p>
        <p class="users-not__desc">Проверьте правильность запроса и повторите поиск.</p>
      </div>
      <p class="users-not__title" v-else>Нет пользователей</p>
    </div>
  </div>
</template>
<script>
  import ClickOutside from 'vue-click-outside';
  import Pagination from '@/components/Pagination.vue';
  import Search from '@/components/Search.vue';
  import Popup from '@/components/UsersPopup.vue';
  import Constants from '@/constants/Constants';

  export default {
      name: 'Users',
      directives: {
          ClickOutside
      },
      components: {
          Search,
          Pagination,
          Popup
      },
      data() {
          return {
              queryString: null,
              isNotFound: false,
              popup: {
                show: false,
                userId: null,
                rightPosition: false
              },
              isNextPage: false,
              isPreviousPage: false,
              countPages: 0,
              users: [],
              order: null
          }
      },
      async created() {
          this.order = this.sorting;
          await this.$store.dispatch('business_region/fetchRegions');
          await this.fetchUsers();
      },
      watch: {
          async order(newVal) {
              this.$store.commit('user/SET_SORTING', newVal);
              await this.fetchUsers();
          },
          async queryString(newVal, oldVal) {
              if (newVal !== oldVal) {
                  await this.$store.dispatch('user/resetPaging');
                  this.fetchUsers();
              }
          }
      },
      methods: {
          closePopup() {
              this.popup.show = false;
          },
          changedOrderState(ascName, descName, currentState) {
              if (currentState === null || ![ascName, descName].includes(currentState)) {
                  return ascName;
              }
              if (currentState === ascName) {
                  return descName;
              }
              return null;
          },
          businessRegionsStrings(ids) {
              const regionsMap = this.$store.getters['business_region/getRegionsMap'];
              const regions = Array.isArray(ids) && regionsMap instanceof Map && regionsMap.size > 0 ? ids.map(id => regionsMap.get(id)).filter(item => !!item) : [];
              return regions.map(region => region.name).sort().join();
          },
          businessDirectionStrings(ids) {
              const regionsMap = this.$store.getters['business_region/getRegionsMap'];
              const regions = Array.isArray(ids) && regionsMap instanceof Map && regionsMap.size > 0 ? ids.map(id => regionsMap.get(id)).filter(item => !!item) : [];
              return [...new Set(regions.map(region => region.direction_name))].sort().join();
          },
          async fetchUsers() {
              let params = {
                  page: this.page,
                  page_size: this.perPage
              };
              const ordering = this.order;
              if (!this.queryString && !ordering) {
                  params = {
                      ...params
                  };
              } else if (!this.queryString && ordering) {
                  params = {
                      ...params,
                      ordering
                  };
              } else if (this.queryString && !ordering) {
                  params = {
                      ...params,
                      search_query: this.queryString
                  };
              } else if (this.queryString && ordering) {
                  params = {
                      ...params,
                      search_query: this.queryString,
                      ordering
                  };
              }
              const usersData = await this.$services.UserService.getUsers(params);
              this.isNextPage = !!usersData.next;
              this.isPreviousPage = !!usersData.previous;
              this.countPages = Math.ceil(usersData.count / this.perPage);
              this.users = usersData.results;
          },
          async changeCurrentPage(page) {
              this.$store.commit('user/SET_PAGE', page);
              await this.fetchUsers();
          },
          changeQueryString(string) {
              this.queryString = string && string.trim().length > 0 ? string.trim() : null;
          },
          getRole(roleId) {
              const role = this.$store.getters['user/getRole'](roleId)
              if (role && role.name) {
                  return role.name
              }
              return '';
          },
          createUser() {
              this.$router.push({ name: 'user-create' });
          },
          editUser(userId) {
              this.$router.push({ name: 'user-edit', params: { userId } });
          },
          async deleteUser(id) {
              this.popup.show = false;
              try {
                await this.$services.UserService.deleteUser(id);
                await this.fetchUsers();
              } catch (err) {
                console.log(err);
                this.$services.MessageService.error('Удаление пользователя невозможно - есть связанные объекты или партнеры');
              }
          },
          showPopup(userId, ref, rightPosition = false) {
              const element = this.$refs[ref][0];
              if (element) {
                  element.appendChild(this.$refs['active_popup']);
                  this.popup.userId = userId;
                  this.popup.show = true;
                  this.popup.rightPosition = rightPosition;
              }
          },
          isAdminOrCallCenterEmployee(roleId) {
              const roles = this.roles.filter(role => [Constants.USER_ROLE_CODE.ADMIN, Constants.USER_ROLE_CODE.CALL_CENTER_EMPLOYEE].includes(role.code)).map(role => role.id);
              return roles.includes(roleId);
          }
      },
      computed: {
          roles() {
              return this.$store.getters['user/getRoles'];
          },
          perPage() {
              return this.$store.getters['user/getPerPage'];
          },
          page() {
              return this.$store.getters['user/getPage'];
          },
          sorting() {
              return this.$store.getters['user/getSorting'];
          },
          isAuthUserAsAdmin() {
              const adminRoleId = this.$store.getters['user/getAdminRoleId'];
              const authUser = this.$store.getters['auth/getAuthUser'];
              return authUser && authUser.role_id === adminRoleId;
          }
      }
  }
</script>

<style scoped>
.max-width-100 {
  max-width: 100px;
}

.users-not__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.users-not__desc {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
</style>
