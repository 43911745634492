<template>
  <div>
    <div class="form-item">
      <p class="form-label">Сертификация</p>
      <div class="form-radio-wrapper">
        <input @change="formTouched" :readonly="isAuth" type="radio" name="is-cert"
          id="has-cert" class="form-input-radio" v-model="isCert"
          :disabled="isAuth" :value="true" />
        <label for="has-cert" class="form-label-radio">С сертификатом</label>
        <input @change="formTouched" type="radio" name="is-cert1" id="no-cert" class="form-input-radio"
          v-model="isCert" :disabled="isAuth" :value="false" />
        <label for="no-cert" class="form-label-radio">Без сертификата</label>
      </div>
      <table v-if="certificates.length" class="socials-table">
        <thead>
          <tr class="socials-table__head">
            <th>Регион</th>
            <th>Ссылка</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in certificates" :key="index">
            <td style="width: 20px;">{{ item.key }}</td>
            <td class="pink">{{ item.link }}</td>
            <td style="width: 14px;">
              <a :href="item.link" target="_blank">
                <img
                  class="region-list__icon"
                  src="@/assets/images/svg/link.svg"
                  alt="Перейти к сертификату"
                />
              </a>
            </td>
            <td style="width: 14px;">
              <img
                class="region-list__icon"
                src="@/assets/images/svg/close.svg"
                alt="Удалить сертификат"
                @click="removeCertClick(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="isCert" class="btn-wrap">
        <button v-for="item in regionButtonKeys" :key="item" class="btn btn-gray btn-region" :disabled="isDisabled(item)" @click="isShowBlock(item)">
          Добавить сертификат {{ item }}
        </button>
      </div>
      <div v-if="showBlock.isCertificateAdding && isCert">
        <div class="form-item" style="margin-bottom: 0px">
          <label class="form-label">Ссылка на сертификат {{ regionKey }}</label>
          <input id="link" class="form-input" type="url" v-model="certificateUrl" />
        </div>
        <p class="form-caption">Внести адрес с протоколом https://</p>
        <div class="add-socials__footer">
          <button class="btn btn-top btn-green" :disabled="!certificateUrl.length" @click="saveCertificate">Добавить</button>
          <button class="btn btn-top btn-gray" @click="closeBlock">
            Отменить
          </button>
        </div>
      </div>
      <div v-if="showBlock.isCertificateSync && isCert">
        <template>
          <el-select
            class="form-select"
            :class="{ error: !isCoordsSet }"
            placeholder="Выберите сертификат"
            :disabled="!isCoordsSet"
            v-model="certificateIndexCA">
            <el-option
            v-for="(item, index) in certificatesCA" :key="index"
            :label="item.pointName"
            :value="index">
            <div style="display: flex; flex-direction: column;">
              <span>{{ item.pointName }}</span>
              <span style="color: #8492a6; font-size: 13px; margin-top: -15px;">{{ item.address }}</span>
            </div>
            </el-option>
          </el-select>
        </template>
        <p v-if="!isCoordsSet" class="message-error">
          Введите адрес
        </p>
        <p v-if="isErrorNoActiveCertificates" class="message-error">
          Активных сертификатов нет. Необходимо перевыпустить сертификат
        </p>
        <div class="add-socials__footer">
          <button class="btn btn-top btn-green" :disabled="!certificateUrl.length" @click="saveCertificate">Добавить</button>
          <button class="btn btn-top btn-gray" @click="closeBlock">
            Отменить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Certification',
  props: {
    /** Есть ли сертификат у партнёра */
    hasCert: {
      type: Boolean,
      default: true
    },
    /** Бизнес-регионы партнёра */
    businessRegions: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    /** Адрес партнёра */
    address: {
      type: String,
      required: true,
      default: ''
    },
    /** ИНН партнёра */
    inn: {
      type: String,
      required: true,
      default: ''
    },
    /** Авторизован пользователь */
    isAuth: {
      type: Boolean,
      required: true,
      default: false
    },
    /** Выбраны ли координаты */
    isCoordsSet: {
      type: Boolean,
      required: true,
      default: false
    },
    /** Партнёр центра сертификации */
    partnerCertificationAuthority: {
      type: Object,
      default() {
        return {}
      }
    },
    /** Сертификаты партнёра */
    partnerCertificates: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isCert: true, // Наличие сертификата
      certificates: [], // Добавленные сертификаты партнёра
      certificateCA: {}, // Сертификат партнёра из ЦС
      showBlock: {
        isCertificateAdding: false, // Отображение блока для добавления сертификата
        isCertificateSync: false // Отображение блока для синхронизации сертификата с ЦС
      },
      regionKey: '', // Ключ добавляемого сертификата
      certificateUrl: '', // Адрес добавляемого сертификата
      certificatesCA: [], // Сертификаты из ЦС
      certificateIndexCA: null // Индекс сертификата из ЦС
    };
  },
  watch: {
    /** Значение есть ли сертификат */
    hasCert() {
      this.isCert = this.hasCert;
    },
    /** Значение наличия сертификата */
    isCert(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!newVal) {
          this.closeBlock();
          this.certificates = [];
        }

      this.$emit('isCert', this.isCert);
      }
    },
    /** Отслеживает получение сертификатов и обновляет в новый формат хранения сертификатов */
    partnerCertificates(newVal, oldVal) {
      if (newVal.length > this.certificates.length) {
        if (newVal[0].key) {
          this.certificates = newVal;
        } else {
          const keys = this.regionButtonKeys;
          for (let index = 0; index < newVal.length; index++) {
            this.certificates.push({
              key: keys[index],
              link: newVal[index]
            });
          }
        }
      }
    },
    /** Отслеживает изменение партнёра из ЦС */
    partnerCertificationAuthority(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.certificateCA = newVal;
      }
    },
    /** Возвращает сертификат полученный от ЦС */
    certificateCA(newVal, oldVal) {
      this.$emit('certificateCA', newVal);

      if (newVal !== null) {
        if (!newVal['WS'] && !newVal['BS']) {
          const certificates = {};
          certificates['WS'] = newVal;
          this.$emit('certificateCA', certificates);
        } else {
          const invalidCertificates = [];
          if (newVal['WS'] && this.certificates.filter(item => item.key === 'WS').length === 0) {
            if (newVal['WS'].certificates === undefined || newVal['WS'].certificates.length === 0) {
              delete this.certificateCA['WS'];
            } else {
              this.certificates.push({
                key: 'WS',
                link: newVal['WS'].certificates[newVal['WS'].certificates.length - 1].shortLink
              });
              if (!newVal['WS'].certificates[newVal['WS'].certificates.length - 1].valid) {
                invalidCertificates.push('WS');
              }
            }
          }
          if (newVal['BS'] && this.certificates.filter(item => item.key === 'BS').length === 0) {
            if (newVal['BS'].certificates === undefined || newVal['BS'].certificates.length === 0) {
              delete this.certificateCA['BS'];
            } else {
              this.certificates.push({
                key: 'BS',
                link: newVal['BS'].certificates[newVal['BS'].certificates.length - 1].shortLink
              });
              if (!newVal['BS'].certificates[newVal['BS'].certificates.length - 1].valid) {
                invalidCertificates.push('BS');
              }
            }
          }

          if (invalidCertificates.length) {
            let message;
            if (invalidCertificates.length > 1) {
                // Если сертификатов больше одного, объединяем их через " и "
                const allButLast = invalidCertificates.slice(0, -1).join(', ');
                const last = invalidCertificates[invalidCertificates.length - 1];
                message = `Действие ваших сертификатов ${allButLast} и ${last} истекло`;
            } else {
                // Если сертификат всего один
                message = `Действие вашего сертификата ${invalidCertificates[0]} истекло`;
            }
            this.$services.MessageService.warning(message);
          }
        }
      }
    },
    /** Отслеживает изменение выбранного сертификата из списка ЦС */
    certificateIndexCA(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal > -1 && newVal !== null) {
          if (this.certificatesCA[newVal].certificates.length) {
            this.certificateUrl = this.certificatesCA[newVal].certificates[0].shortLink;
          } else {
            this.certificateUrl = '';
          }
        }
      }
    },
    /** Отслеживает изменения в массиве с сертификатами и возвращает их в новом формате кроме WS */
    certificates(newVal, oldVal) {
      if (this.certificateCA) {
          this.$emit('update', newVal.filter(item => item.key !== 'WS' && item.key !== 'BS'));
      } else {
          this.$emit('update', newVal);
        }
    },
    /** Отслеживает изменение координат и отправляет запрос в ЦС если открыт блок */
    isCoordsSet(newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.regionKey === 'WS') {
        this.sendCertificationAuthority();
      }
    },
    /** Отслеживает изменения в адресе */
    address(newVal, oldVal) {
      if (this.regionKey === 'WS' || this.regionKey === 'BS') {
        this.certificateIndexCA = null;
      }

      if (!this.isCoordsSet && newVal !== oldVal && this.certificates.filter(item => item.key === 'WS' || item.key === 'BS').length) {
        this.certificates = this.certificates.filter(item => item.key !== 'WS' && item.key !== 'BS');
        delete this.certificateCA['WS'];
        delete this.certificateCA['BS'];
        this.$emit('certificateCA', this.certificateCA);
        this.$services.MessageService.warning(
          'Проведите синхронизацию с Центром сертификации повторно'
        );
      }
    },
    /** Следит за изменением в массиве с выбранными бизнес-регионами */
    businessRegions() {
      this.closeBlock();
    },
    /** Отслеживает изменение в списке ключей регионов, для валидации списка сертификатов */
    regionButtonKeys(newVal, oldVal) {
      if (newVal.length < oldVal.length && this.isCert) {
        const removeKey = oldVal.filter((item) => !newVal.includes(item));
        this.certificates = this.certificates.filter((item) => !removeKey.includes(item.key));
      }
    }
  },
  methods: {
    /** Вносились изменения */
    formTouched() {
      this.$emit('change', true);
    },
    /** Удаление сертификата из списка по индексу */
    removeCertClick(value) {
      if (this.certificates[value].key === 'WS' || this.certificates[value].key === 'BS') {
        delete this.certificateCA[this.certificates[value].key];
      }
      this.certificates.splice(value, 1);
    },
    /** Доступ к кнопке добавления сертификата */
    isDisabled(value) {
      const regionSelected = value === this.regionKey;
      const cert = this.certificates.filter((item) => item.key.includes(value));
      return !!(regionSelected || cert.length);
    },
    /** Отображение блока для добавления сертификата */
    isShowBlock(value) {
      this.showBlock.isCertificateSync = false;
      this.showBlock.isCertificateAdding = false;
      this.regionKey = value;

      // Если регион WS или BS, то отправляем запрос на получение сертификата
      if (value === 'WS' || value === 'BS') {
        if (this.isCoordsSet) {
          this.sendCertificationAuthority(value);
        }
        this.showBlock.isCertificateSync = true;
      } else {
        this.showBlock.isCertificateAdding = true;
      }
    },
    /** Отправка запроса на получение сертификата из ЦС */
    async sendCertificationAuthority(type) {
      try {
        let response = null;

        if (type === 'WS') {
          response = await axios({
            method: 'post',
            url: process.env.VUE_APP_API_CERT_AUTHORITY_LINK,
            withCredentials: true,
            data: {
              address: this.address, // г Уфа, ул Зеленоградская, д 10
              inn: this.inn,
              onlyValid: true
            },
            headers: {
              'content-type': 'application/json',
              ApiSecurityKey: process.env.VUE_APP_API_CERT_SECURITY_KEY
            }
          });
        } else if (type === 'BS') {
          response = await axios({
            method: 'post',
            url: process.env.VUE_APP_API_CERT_AUTHORITY_LINK_BS,
            withCredentials: true,
            data: {
              address: this.address,
              inn: this.inn,
              onlyValid: true
            },
            headers: {
              'content-type': 'application/json'
            }
          });
        }
        this.certificatesCA = response.data.partners;
      } catch (err) {
        this.closeBlock();
        this.$services.MessageService.error(
          'Ошибка при получении данных от Центра сертификации'
        );
      }
    },
    /** Сохранение сертификата */
    saveCertificate() {
      if (this.regionKey === 'WS' || this.regionKey === 'BS') {
        const certificates = { ...this.certificateCA };
        certificates[this.regionKey] = this.certificatesCA[this.certificateIndexCA];
        this.certificateCA = certificates;
        this.$emit('certificateCA', this.certificateCA);
      } else {
        this.certificates.push({
          key: this.regionKey,
          link: this.certificateUrl
        });
      }
      this.closeBlock();
    },
    /** Закрытие блока ввода/выбора сертификата */
    closeBlock() {
      this.showBlock.isCertificateAdding = false;
      this.showBlock.isCertificateSync = false;
      this.certificateUrl = '';
      this.regionKey = '';
      this.certificatesCA = [];
      this.certificateIndexCA = null;
    }
  },
  computed: {
    /** Ключи кнопок регионов */
    regionButtonKeys() {
      let keys = [];
      if (!this.businessRegions.length) {
        keys = [];
      } else {
        for (let i = 0; i < this.businessRegions.length; i++) {
          const key = this.businessRegions[i].name.split('_')[0];
          if (!keys.includes(key)) {
            keys.push(key);
          }
        }
      }
      return keys;
    },
    /** Нет активных сертификатов */
    isErrorNoActiveCertificates() {
      if (this.certificateIndexCA !== null) {
        if (this.certificatesCA[this.certificateIndexCA].certificates.length === 0) {
          this.$services.MessageService.warning(
          'Активных сертификатов нет. Необходимо перевыпустить сертификат'
          );
          return true;
        }
      }

      return false;
    }
  }
}
</script>

<style scoped>
.form-item {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-radio-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-caption {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 18px;
  font-style: italic;
  color: #4e4e4e;
}

.form-input-radio {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-label-radio {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-radio:not(:last-child) {
  margin-right: 20px;
}

.form-label-radio::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: #fff;
}

.form-input-radio:checked+.form-label-radio::before {
  background-color: #000;
}

.form-input-radio:checked+.form-label-radio::before {
  background-color: #000;
}

.form-select.error {
  border: 1px solid #dc143c;
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.btn-region {
  margin: 0px 5px 5px 0px;
  width: 220px;
}

.btn-top {
  margin-right: 15px;
}

.message-error {
  color: #dc143c;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}

.socials-table {
  margin-bottom: 15px;
  width: 100%;
}

.socials-table th,
td {
  text-align: left;
  padding: 15px;
}

.socials-table tr.socials-table__head {
  background: transparent;
}

.socials-table tr {
  background: #ffffff;
}

.socials-table tr.selected {
  font-weight: 700;
}

.socials-table tr.socials-table__head:hover {
  background: transparent;
}

.socials-table th {
  border-bottom: 1px solid #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.socials-table td {
  font-size: 18px;
  line-height: 24px;
}

.socials-table td img {
  max-height: 24px;
  max-width: 24px;
}

.region-list__icon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #000000;
  margin-bottom: 5px;
}
</style>
