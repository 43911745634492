<template>
  <div class="g-container">
    <div ref="active_popup">
      <Popup v-if="isAuthUserAsAdmin"
             v-click-outside="closePopup"
             :item-id="popup.itemId"
             :show-popup="popup.show"
             @onEdit="editItem(popup.itemId)"
             @onDelete="confirmDelete"
      ></Popup>
    </div>
    <ConfirmPopup
      :text="`Удалить географический регион ${popup.itemName}?`"
      :show="popup.confirm"
      @close="popup.confirm = false"
      @confirm="deleteItem(popup.itemId)"
    ></ConfirmPopup>
    <AddGeoRegion
      :visible="modal.show"
      :initial-name="modal.item"
      :initial-country="modal.country"
      :initial-cladr="modal.cladr"
      :initial-fias="modal.fias"
      :item-id="popup.itemId"
      :mode="modal.type"
      @close="modal.show = false"
      @update="fetchItems"
    ></AddGeoRegion>
    <div class="users-page">
      <h2 class="users-page__title">Географические регионы</h2>
      <div class="flex justify-between items-start">
        <button class="btn btn-green" v-if="isAuthUserAsAdmin" @click="addItem">Добавить географический регион</button>
        <Search :is-not-found="isNotFound" @query="changeQueryString($event)" place-holder="Поиск"></Search>
      </div>
      <div class="table-wrapper">
        <table class="users-table">
          <thead class="users-table__head">
            <tr>
              <th>
                <div class="users-table__box" @click="order = changedOrderState('name', '-name', order)">
                  <span class="users-table__title">Название</span>
                  <img
                    :class="{'users-table-rotate__icon': order === '-name', 'opacity-0': !['name', '-name'].includes(order)}"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th>
                <div class="users-table__box" @click="order = changedOrderState('country_name', '-country_name', order)">
                  <span class="users-table__title">Страна</span>
                  <img
                    :class="{'users-table-rotate__icon': order === '-country_name', 'opacity-0': !['country_name', '-country_name'].includes(order)}"
                    class="users-table__icon"
                    src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th>
                <div class="users-table__box" @click="order = changedOrderState('cladr_code', '-cladr_code', order)">
                  <span class="users-table__title">Код КЛАДР</span>
                  <img
                    :class="{'users-table-rotate__icon': order === '-cladr_code', 'opacity-0': !['cladr_code', '-cladr_code'].includes(order)}"
                    class="users-table__icon"
                    src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th>
                <div class="users-table__box" @click="order = changedOrderState('fias_code', '-fias_code', order)">
                  <span class="users-table__title">Код ФИАС</span>
                  <img
                    :class="{'users-table-rotate__icon': order === '-fias_code', 'opacity-0': !['fias_code', '-fias_code'].includes(order)}"
                    class="users-table__icon"
                    src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in itemsList" :key="index">
              <td @contextmenu.prevent="showPopup(item.id, `name${item.id}`)">
                <div class="users-box" :ref="`name${item.id}`">
                  <span class="users-table__desc truncate">{{ item.name }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.id, `country${item.id}`)">
                <div class="users-box" :ref="`country${item.id}`">
                  <span class="users-table__desc truncate">{{ getCountryName(item.country_id) }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.id, `cladr${item.id}`)">
                <div class="users-box" :ref="`cladr${item.id}`">
                  <span class="users-table__desc truncate">{{ item.cladr_code }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.id, `fias${item.id}`)">
                <div class="users-box" :ref="`fias${item.id}`">
                  <span class="users-table__desc truncate">{{ item.fias_code }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :current-page="page"
        :total-pages="countPages"
        :per-page="itemsList.length"
        :is-next-users-page="isNextPage"
        :is-previous-users-page="isPreviousPage"
        @onNext="changeCurrentPage"
        @onPrevious="changeCurrentPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside';
  import Pagination from '@/components/Pagination.vue';
  import Search from '@/components/Search.vue';
  import Popup from '@/components/RegionsPopup.vue';
  import ConfirmPopup from '@/components/ConfirmPopup.vue';
  import AddGeoRegion from '@/components/AddGeoRegion.vue';

  export default {
    name: 'GeoRegions',
    directives: {
        ClickOutside
    },
    components: {
      Search,
      Pagination,
      Popup,
      ConfirmPopup,
      AddGeoRegion
    },
    data() {
      return {
        queryString: '',
        isNotFound: false,
        popup: {
            show: false,
            confirm: false,
            itemId: null,
            rightPosition: false
        },
        modal: {
          show: false,
          item: '',
          country: '',
          cladr: '',
          fias: '',
          type: 'add'
        },
        order: null,
        page: 0,
        perPage: 10,
        isNextPage: false,
        isPreviousPage: false,
        countPages: 1
      }
    },
    async mounted() {
      await this.fetchItems();
      await this.$store.dispatch('country/fetchCountries');
    },
    watch: {
      async order() {
        this.page = 0;
        await this.fetchItems();
      },
      async queryString(newVal, oldVal) {
        if (newVal !== oldVal) {
          await this.fetchItems();
        }
      }
    },
    methods: {
      changedOrderState(ascName, descName, currentState) {
          if (currentState !== ascName && currentState !== descName) {
              return ascName;
          }
          if (currentState === ascName) {
              return descName;
          }
          if (currentState === descName) {
              return null;
          }
      },
      showPopup(itemId, ref) {
        const element = this.$refs[ref][0];
        if (element) {
          element.appendChild(this.$refs['active_popup']);
          this.popup.itemId = itemId;
          this.popup.show = true;
        }
      },
      closePopup() {
          this.popup.show = false;
      },
      confirmDelete() {
        this.popup.show = false;
        const itemToDelete = this.itemsList.find(item => item.id === this.popup.itemId);
        if (itemToDelete) {
          this.popup.itemName = itemToDelete.name;
          this.popup.confirm = true;
        }
      },
      async deleteItem(id) {
        this.popup.confirm = false;
        try {
          await this.$services.RegionService.deleteGeoRegion(id);
          await this.fetchItems();
        } catch (err) {
          console.log(err);
          this.$services.MessageService.error('Удаление невозможно – географический регион имеет связанные бизнес-регионы');
        }
      },
      addItem() {
        this.modal.type = 'add';
        this.modal.item = '';
        this.modal.country = '';
        this.modal.cladr = '';
        this.modal.fias = '';
        this.modal.show = true;
      },
      editItem(id) {
        const itemToEdit = this.itemsList.find(item => item.id === id);
        if (itemToEdit) {
          const index = this.itemsList.findIndex(item => item.id === id);
          this.modal.type = 'edit';
          this.modal.item = itemToEdit.name;
          this.modal.cladr = itemToEdit.cladr_code;
          this.modal.fias = itemToEdit.fias_code;
          this.modal.country = itemToEdit.country_id;
          this.modal.show = true;
        }
      },
      async changeQueryString(string) {
          this.queryString = string;
          if (this.queryString.length > 3) {
            await this.fetchItems();
          }
      },
      async fetchItems() {
          let params = {
              page: this.page,
              page_size: this.perPage
          };
          // const ordering = this.serializeOrdering();
          const ordering = this.order;
          if (this.queryString.length === 0 && !ordering) {
              params = {
                  ...params
              };
          } else if (this.queryString.length === 0 && ordering) {
              params = {
                ...params,
                ordering
              };
          } else if (this.queryString.length > 0 && !ordering) {
              params = {
                  ...params,
                  search_query: this.queryString
              };
          } else if (this.queryString.length > 0 && ordering) {
              params = {
                  ...params,
                  search_query: this.queryString,
                  ordering
              };
          }
          const data = await this.$services.RegionService.getGeoRegions(params);
          this.isNextPage = !!data.next;
          this.isPreviousPage = !!data.previous;
          this.countPages = Math.ceil(data.count / this.perPage);
          await this.$store.dispatch('geo_region/setGeoRegions', data.results);
      },
      async changeCurrentPage(page) {
          this.page = page;
          await this.fetchItems();
      },
      serializeOrdering() {
          return Object.entries(this.orders).map(item => item[1]).filter(item => item !== null).join().trim();
      },
      getCountryName(id) {
        const country = this.countries.find(item => item.id === id);
        return country ? country.name : '';
      }
    },
    computed: {
        isAuthUserAsAdmin() {
            const adminRoleId = this.$store.getters['user/getAdminRoleId'];
            const authUser = this.$store.getters['auth/getAuthUser'];
            return authUser.role_id === adminRoleId;
        },
        itemsList() {
            return this.$store.getters['geo_region/getGeoRegions'];
        },
        countries() {
          return this.$store.getters['country/getCountries'];
        }
    }
  }
</script>

<style scoped>
.table-num {
  width: 5%;
}

.max-width-100 {
  max-width: 100px;
}
</style>
