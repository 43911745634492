<template>
    <div class="input">
      <div ref="wrapper">
        <slot></slot>
      </div>
      <div class="icon cursor-pointer" @click="toggle">
        <img v-if="hidden" src="../assets/images/svg/eye-close.svg">
        <img v-else src="../assets/images/svg/eye-open.svg">
      </div>
    </div>
</template>

<script>
  export default {
      name: 'SearchInput',
      data() {
          return {
              type: 'text',
              hidden: true
          }
      },
      methods: {
          toggle() {
            const wrapper = this.$refs['wrapper'];
            const input = wrapper.querySelector('input');
            if (input) {
                if (input.type === 'password') {
                    input.type = this.type;
                    this.hidden = false;
                } else {
                    input.type = 'password';
                    this.hidden = true;
                }
            }
          }
      }
  }
</script>

<style scoped>
  .input {
    position: relative;
  }
  .icon{
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
