import axios from '@/axios';
import Service from './Service';

export default class SocialsService extends Service {
    constructor(name = 'SocialsService', namespace = null, ...args) {
        super(name, namespace);
    }

    async getSuggests(query) {
        const response = await axios.get('/api/v1/get_address_suggests', { params: { query } });
        return response;
    }

    async getFullAddress(query) {
      const response = await axios.get('/api/v1/standardize_address', { params: { query } });
      return response;
    }
}
