import state from './AuthState';
import mutations from './AuthMutations';
import actions from './AuthActions';
import getters from './AuthGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
