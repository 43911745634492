<template>
  <div>
    <Dialog :title="title" :visible.sync="isVisible" @closed="close">
      <form @submit.prevent="save">
        <div class="form-item">
          <label class="form-label" for="system-name">Название *</label>
          <input id="system-name" class="form-input" type="text" :value="name"
            @input="enteredName = $event.target.value">
        </div>
        <div class="add-socials__footer">
          <button type="submit" class="btn btn-top btn-green btn-small">Сохранить</button>
          <button class="btn btn-top btn-gray btn-small" @click.prevent="close">Закрыть</button>
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';

export default {
  name: 'AddSystems',
  components: {
    Dialog
  },
  props: {
    visible: Boolean,
    mode: String,
    initialName: String,
    new: Boolean,
    itemId: {
      type: String,
      default: ''
    },
    group: String
  },
  data() {
    return {
      isVisible: false,
      enteredName: null
    }
  },
  watch: {
    visible(newVal) {
      this.isVisible = newVal;
    }
  },
  methods: {
    async save() {
      if (!this.name) { return; }
      if (this.new) {
        this.$emit('newItem', this.name);
        this.close();
        return;
      }
      try {
        if (this.mode === 'add') {
          await this.addItem();
        } else if (this.mode === 'edit') {
          await this.editItem();
        }
        const newData = await this.$services.ProductService.getProducts();
        this.$store.dispatch('systems/setProducts', newData.results);
        this.close();
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error(`Ошибка при ${this.mode === 'add' ? 'добавлении' : 'изменении'} данных`)
      }
    },
    async addItem() {
      await this.$services.ProductService.addProduct({ name: this.name, product_group: this.group });
      this.$services.MessageService.success('Продукт успешно добавлен');
    },
    async editItem() {
      await this.$services.ProductService.updateProduct({ name: this.name, product_group: this.group }, this.itemId);
      this.$services.MessageService.success('Данные успешно обновлены');
    },
    close() {
      this.isVisible = false;
      this.enteredName = null;
      this.enteredNum = null;
      this.$emit('close');
    }
  },
  computed: {
    title() {
      return `${this.mode === 'add' ? 'Добавить' : 'Редактировать'} систему или продукт`;
    },
    name() {
      return this.enteredName === null ? this.initialName : this.enteredName;
    }
  }
}
</script>

<style scoped>
.btn-top:first-child {
  margin-right: 15px;
}

.form-item {
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.add-socials__footer {
  margin-top: 30px;
}
</style>
