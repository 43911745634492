import state from './GeoRegionState';
import mutations from './GeoRegionMutations';
import actions from './GeoRegionActions';
import getters from './GeoRegionGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
