<template>
    <div class="ds-per-page-select">
        <span v-if="title" :class="titleSize">
            {{ title }}
        </span>
        <div class="ds-pagination">
            <button v-for="(item, index) in list" :key="index" class="ds-pagination__item"
                :class="[buttonSize, { 'ds-pagination__item--active': current === item }]" :data-num="item"
                @click="changeCurrent(item)">
                <span class="ds-pagination__item__text">
                    {{ item }}
                </span>
            </button>
        </div>
    </div>
</template>

<script lang="js">
import _ from 'lodash';

export default {
    name: 'PerPageSelectPagination',
    props: {
        title: {
            type: String,
            default: 'Элементов:'
        },
        list: {
            type: Array,
            default: () => ['10', '20', '50', 'Все']
        },
        perPage: {
            type: String,
            default: '10'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            current: this.perPage > '50' ? 'Все' : this.perPage
        };
    },
    computed: {
        titleSize() {
            return {
                'ds-per-page-select__title--m': true,
                'ds-per-page-select__disabled': this.disabled
            }
        },
        buttonSize() {
            return {
                'ds-pagination__item': true,
                'ds-pagination__item--m': true,
                'ds-pagination__disabled': this.disabled
            }
        },
        selectList() {
            return this.list.map(item => ({
                title: item,
                id: item
            }));
        }
    },
    methods: {
        changeCurrent(num) {
            let number = num;
            if (_.isNaN(number)) return;
            if (number < 1) {
                number = 1;
            }
            if (number > this.count) {
                number = this.count;
            }
            if (this.current === number) return;
            this.current = number;
            this.$emit('currentPerPage', this.current);
        }
    }
}
</script>

<style lang="scss" scoped>
.ds-per-page-select {
    align-items: center;
    display: flex;
    gap: 8px;

    &__disabled {
        color: #a9a9a9;
    }

    &__title {
        color: #000000;

        &--m {
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            height: 40px;
            min-width: 40px;
            padding: 8px;
        }
    }
}

.ds-pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &__item {
        background-color: transparent;
        border: none;
        cursor: pointer;
        line-height: normal !important;
        position: relative;
        transition: color .15s ease;
        width: 32px;

        &--m {
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            height: 40px;
            min-width: 40px;
            padding: 8px;
        }

        &__text {
            position: relative;
        }

        &:hover {
            border: 1px solid #000000;
        }

        &:before {
            background-color: #000000;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform: scale(0);
            transition: transform ease;
        }

        &--active {
            color: #fff;

            &:before {
                transform: scale(1);
            }
        }
    }
}
</style>
