import AuthHelper from '@/helpers/AuthHelper';

export default {
    REMOVE_AUTH(state) {
        AuthHelper.removeAuth();
        state.authUser = null;
    },
    SET_AUTH_USER(state, user) {
        state.authUser = user;
    }
}
