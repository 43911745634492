<template>
  <div>
    <Dialog :title="title" :visible.sync="isVisible" @closed="close">
      <form @submit.prevent="save">
        <div class="form-item">
          <label class="form-label" for="system-name">Название *</label>
          <input id="system-name" class="form-input" type="text" v-bind:value="name"
            @input="entered.name = $event.target.value">
        </div>
        <div class="form-item">
          <p class="form-label">Страна *</p>
          <template>
            <el-select :value="country" @change="entered.country = $event" placeholder="Страна" class="form-select">
              <el-option class="form-option" v-for="country in countries" :key="country.id" :label="country.name"
                :value="country.id">
              </el-option>
            </el-select>
          </template>
        </div>
        <div class="form-item">
          <label class="form-label" for="cladr">Код КЛАДР *(для регионов РФ)</label>
          <input id="cladr" class="form-input" type="text" v-bind:value="cladr"
            @input="entered.cladr = $event.target.value">
        </div>
        <div class="form-item">
          <label class="form-label" for="fias">Код ФИАС *(для регионов РФ)</label>
          <input id="fias" class="form-input" type="text" v-bind:value="fias"
            @input="entered.fias = $event.target.value">
        </div>
        <div class="add-socials__footer">
          <button type="submit" class="btn btn-top btn-green btn-small">Сохранить</button>
          <button class="btn btn-top btn-gray btn-small" @click="close">Закрыть</button>
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';

export default {
  name: 'AddGeoRegion',
  components: {
    Dialog
  },
  props: {
    visible: Boolean,
    mode: String,
    itemType: String,
    itemName: String,
    initialName: String,
    initialCountry: String,
    initialCladr: String,
    initialFias: String,
    initialCountryId: String,
    itemId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isVisible: false,
      entered: {
        name: null,
        cladr: null,
        fias: null,
        country: null
      }
    }
  },
  watch: {
    visible(newVal) {
      this.isVisible = newVal;
    }
  },
  methods: {
    save() {
      if (!this.name || !this.country) { return; }
      const region = {
        name: this.name,
        country_id: this.country,
        cladr_code: this.cladr || '',
        fias_code: this.fias || ''
      };
      if (this.mode === 'add') {
        this.addItem(region);
      } else if (this.mode === 'edit') {
        this.editItem(region);
      }
      this.close();
    },
    async addItem(region) {
      try {
        await this.$services.RegionService.addGeoRegion(region);
        this.$emit('update');
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при добавлении данных');
      }
    },
    async editItem(region) {
      try {
        await this.$services.RegionService.updateGeoRegion(region, this.itemId);
        this.$emit('update');
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при изменении данных');
      }
    },
    close() {
      this.entered.name = null;
      this.entered.num = null;
      this.entered.country = null;
      this.entered.cladr = null;
      this.entered.fias = null;
      this.$emit('close');
    }
  },
  computed: {
    title() {
      return `${this.mode === 'add' ? 'Добавить' : 'Редактировать'} географический регион`;
    },
    name() {
      return this.entered.name === null ? this.initialName : this.entered.name;
    },
    country() {
      return this.entered.country === null ? this.initialCountry : this.entered.country;
    },
    cladr() {
      return this.entered.cladr === null ? this.initialCladr : this.entered.cladr;
    },
    fias() {
      return this.entered.fias === null ? this.initialFias : this.entered.fias;
    },
    countries() {
      return this.$store.getters['country/getCountries'];
    }
  }
}

</script>

<style scoped>
.btn-top:first-child {
  margin-right: 15px;
}

.form-item {
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.add-socials__footer {
  margin-top: 30px;
}
</style>
