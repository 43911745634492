<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { loadYmap } from 'vue-yandex-maps';

  export default {
      name: 'App',
      data() {
          return {}
      },
      async created() {
        await loadYmap({ apiKey: process.env.VUE_APP_MAP_KEY, lang: 'ru_RU' });
      }
  }
</script>

<style lang="scss"></style>
