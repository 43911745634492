export default {
    page: 0,
    perPage: 10,
    sorting: {
      id: null,
      name: null,
      type: null,
      is_show_on_map: null,
      year_of_completion: null,
      business_regions: null,
      curator: null,
      additional_curators: null
    },
    facilityTypes: []
}
