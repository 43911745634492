import state from './SocialState';
import mutations from './SocialMutations';
import actions from './SocialActions';
import getters from './SocialGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
