<template>
  <div>
    <Dialog title="Импорт данных" :visible.sync="isVisible" @closed="close()">
      <div class="form-item">
        <label class="form-label">Тип загрузки</label>
        <div class="form-select-wrapper">
          <template>
            <Select v-model="importType" collapse-tags placeholder="Выберите тип" class="form-select"
              @change="selectType">
              <Option v-for="element in this.importDataOptions" :key="element.id" :label="element.title"
                :value="element.id">
              </Option>
            </Select>
          </template>
        </div>
      </div>
      <div v-if="importType">
        <div class="file-name">{{ this.importData ? this.importData.name : '' }}</div>
        <div class="form-item">
          <div class="btn-wrapper">
            <input type="file" class="download" id="importData" @change="uploadFile($event)">
            <label for="importData" class="btn btn-gray">Загрузить файл</label>
            <button class="btn btn-top btn-gray-border" @click="deleteFile">Удалить файл</button>
          </div>
        </div>
      </div>
      <div class="form-item" v-if="errors">
        <textarea class="error-textarea" :rows="4" v-model="formatError" :readonly="true" />
      </div>
      <div class="add-socials__footer">
        <button class="btn btn-top btn-green" @click="save">Загрузить</button>
        <button class="btn btn-top btn-gray" @click="close">Закрыть</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Select, Option, Dialog } from 'element-ui';
import Constants from '@/constants/Constants';

export default {
  name: 'AddSocials',
  components: {
    Select,
    Option,
    Dialog
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      isVisible: false,
      importType: null,
      importData: null,
      errors: null
    }
  },
  created() {
    this.importDataOptions = Constants.IMPORT_DATA_OPTIONS
  },
  computed: {
    formatError() {
      return this.errors.map(el => `${el}\n`).join('')
    }
  },
  watch: {
    visible(newVal) {
      this.isVisible = newVal;
    }
  },
  methods: {
    async save() {
      if (!this.importType) {
        return;
      }
      try {
        const res = await this.$services.ImportDataService.uploadFile({
          uploadType: this.importType,
          file: this.importData
        });
        if (res.data?.errors.length) {
          this.errors = res.data.errors
        } else {
          this.$services.MessageService.success('Данные успешно загружены');
          setTimeout(() => {
            this.clear();
            this.close()
          }, 2000);
        }
      } catch (err) {
        this.errors = err.data?.error_message ? [err.data.error_message] : [err.data.detail]
      }
    },
    close() {
      this.isVisible = false;
      this.$emit('close');
      this.clear();
    },
    clear() {
      this.importData = null;
      this.importType = null;
    },
    deleteFile() {
      this.importData = null;
      this.errors = null;
    },
    uploadFile(event) {
      this.importData = event.target.files[0]
    },
    selectType(type) {
      this.importType = type;
    }
  }
}
</script>

<style scoped>
.btn-top {
  margin: 0 7.5px;
  font-size: 18px;
  line-height: 21px;
}

.form-item {
  margin-bottom: 20px;
}

.btn-wrapper {
  display: flex;
  padding: 15px 0;
  align-items: center;
  border-top: 0;
}

.download {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.file-name {
  min-height: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.form-input-check[type="checkbox"]:checked+.form-label-check::after {
  opacity: 1;
}

.form-input-radio[type="radio"]:checked+.form-label-radio::after {
  opacity: 1;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.error-textarea {
  width: 100%;
  font-size: 14px;
  min-height: 33px;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  color: #ff2424;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #ff2424;
  border-radius: 4px;
  cursor: default;
  outline: none;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}
</style>
