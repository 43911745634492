<template>
  <div class="fs full-screen">
    <div class="fs-content-wrapp">
      <img class="fs-logo" src="../../assets/images/svg/logo.svg" alt="">
      <div class="fs-content">
        <div class="fs-title">Восстановление пароля</div>
        <div class="login-form">
          <label class="fs-label">Новый пароль</label>
          <div class="fs-input" :class="{ 'fs-input--error ': errorMessages.password }">
            <PasswordInput>
              <input v-model="password" type="password">
            </PasswordInput>
          </div>
          <p v-show="errorMessages.password" class="fs-pink">{{ errorMessages.password }}</p>
          <button @click="login" class="btn btn-login btn-green" type="submit">
            Войти
            <span class="fs-icon">
              <img src="../../assets/images/svg/arrow-right.svg" alt="">
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UrlHelper from '@/helpers/UrlHelper';
import ValidationHelper from '../../helpers/ValidationHelper';
import PasswordInput from '../../components/PasswordInput.vue';

export default {
  name: 'ResetPassword',
  components: {
    PasswordInput
  },
  data() {
    return {
      password: '',
      token: '',
      errorMessages: {
        password: null
      }
    }
  },
  created() {
    this.token = UrlHelper.getQueryParameter('token');
  },
  watch: {
    password() {
      this.passwordValidator();
    }
  },
  methods: {
    async login() {
      const payload = {
        password: this.password,
        token: this.token
      };
      try {
        if (!this.errorMessages.password) {
          await this.$store.dispatch('auth/resetPassword', payload);
          await this.$router.push({ name: 'login' });
        }
      } catch (err) {
        console.log(err);
      }
    },
    passwordValidator() {
      let message = null;
      const check = ValidationHelper.validatePassword(this.password);
      if (!this.password) {
        message = null;
      } else if (!check.isValid) {
        message = check.errorMessage;
      }
      this.errorMessages.password = message;
    }
  }
}
</script>

<style scoped>
.full-screen {
  padding-top: 200px;
  height: auto;
  display: block;
}

.fs-content-wrapp {
  width: 460px;
  margin: auto;
}

.fs-content {
  position: relative;
  padding: 30px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.fs-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 30px;
}

.fs-logo {
  width: 140px;
  margin-bottom: 30px;
}

.fs-pink {
  font-size: 16px;
  line-height: 24px;
  color: #DC143C;
}

.fs-input--error {
  border: 1px solid #DC143C;
}

.fs-label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin: 5px 0;
}

.btn-login {
  margin: 20px 0;
}

.fs-icon {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 16px;
}

.fs-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  padding: 10px;
  margin: 10px 0;
}
</style>
