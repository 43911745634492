<template>
  <div class="g-container system-edit">
    <AddSystems :visible="modal.show" :mode="modal.mode" :group="modal.group" :item-id="modal.itemId"
      :initial-num="modal.num" :initial-name="modal.name" :new="modal.new" @newItem="addNewProduct($event)"
      @close="closeModal"></AddSystems>
    <ConfirmPopup :show="modal.confirm" :text="modal.text" @confirm="deleteItem" @close="modal.confirm = false">
    </ConfirmPopup>
    <h1 class="system-edit__title">Системы и продукты</h1>
    <div class="btn-wrapper system-edit__btn-wrapper">
      <button class="btn btn-top btn-green btn-small mr-15" @click="saveChanges">Сохранить</button>
      <button class="btn btn-top btn-gray btn-small mr-15" @click="discardChanges">Отменить</button>
    </div>
    <div class="system-edit__main">
      <p class="system-edit__caption">Внимание! Поля, обозначенные знаком *, обязательны к заполнению!</p>
      <h2 class="system-edit__subtitle system-edit__subtitle--fw-700">Направление</h2>
      <form class="system-edit__tabs">
        <div class="system-edit__tab-container" v-for="direction in directions" :key="direction.id">
          <input type="radio" name="tab" :id="direction.id" class="system-edit__radio"
            :checked="activeTab === direction.id" @change="setActiveTab(direction.id)">
          <label :for="direction.id" class="system-edit__label">{{ direction.name }}</label>
        </div>

      </form>
      <h2 class="system-edit__subtitle system-edit__subtitle--fw-700 system-edit__subtitle--mb-large">Системы и продукты
      </h2>
      <h2 class="system-edit__subtitle">Количество групп</h2>
      <el-select :value="currNumGroups" @change="changeGroupsNum($event)" class="system-edit__num-select">
        <el-option v-for="(item, index) in selectGroups" filterable :key="item" :label="item" :value="item"
          :selected="index === 1">
        </el-option>
      </el-select>

      <div class="system-edit__panel">
        <section class="system-edit__section" v-for="(group, i) in groups" :key="i">
          <label :for="`group-${i}`" class="system-edit__subtitle">Название группы*</label>
          <input :id="`group-${i}`" type="text" class="system-edit__group-name" :value="group.name"
            @input="editGroupName(group.id, $event)">
          <table class="users-table">
            <thead class="users-table__head">
              <tr>
                <th>Название</th>
              </tr>
            </thead>
            <tbody v-if="productsByGroup(group.id).length">
              <tr v-for="(product, j) in productsByGroup(group.id)" :key="j"
                @click="selectProduct(group.id, product.id)" :class="{ 'selected': product.selected }">
                <td>
                  <span class="users-table__desc">{{ product.name }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex flex-row">
            <button class="btn btn-top btn-gray btn-small mr-10" @click="showModal('add',
            productsByGroup(group.id).length + 1,
            '',
            group.id,
            '')">
              Добавить
            </button>
            <button class="btn btn-top btn-gray btn-small mr-10" :disabled="activeProduct(group.id) === null" @click="showModal(
            'edit',
            productsByGroup(group.id).findIndex(product => product.selected) + 1,
            activeProduct(group.id).name,
            group.id,
            activeProduct(group.id).id)">
              Редактировать
            </button>
            <button class="btn btn-top btn-white btn-small mr-10"
              @click="confirmDeleteProduct(group.id)">Удалить</button>
          </div>
        </section>
        <section class="system-edit__section" v-if="newGroup.created">
          <label :for="newGroup" class="system-edit__subtitle">Название группы*</label>
          <input id="newGroup" type="text" class="system-edit__group-name" v-model="newGroup.name">
          <table class="users-table">
            <thead class="users-table__head">
              <tr>
                <th class="system-edit__num">№</th>
                <th>Название</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, j) in newGroup.list" :key="j" @click="selectNewProduct(j)"
                :class="{ 'selected': product.selected }">
                <td>
                  <span class="users-table__desc">{{ j + 1 }}</span>
                </td>
                <td>
                  <span class="users-table__desc">{{ product.name }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex flex-row">
            <button class="btn btn-top btn-gray btn-small mr-10" @click="showModal('add',
            newGroup.list.length + 1,
            '',
            '',
            '',
            true)">
              Добавить
            </button>
            <button class="btn btn-top btn-white btn-small mr-10" @click="deleteNewProduct">
              Удалить
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AddSystems from '@/components/AddSystems.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';

export default {
  name: 'Systems',
  components: {
    AddSystems,
    ConfirmPopup
  },
  data() {
    return {
      modal: {
        show: false,
        mode: 'add',
        num: 0,
        name: '',
        confirm: false,
        type: '',
        text: '',
        group: '',
        itemId: '',
        new: false
      },
      activeTab: 'ws',
      currNumGroups: null,
      isEditing: false,
      newGroup: {
        created: false,
        name: '',
        list: []
      },
      editName: {
        id: null,
        oldName: ''
      }
    }
  },
  async created() {
    await this.$store.dispatch('directions/fetchDirections');
    await this.fetchGroups();
    await this.fetchProducts();
    this.setActiveTab(this.directions[0].id);
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.groups.forEach(group => {
        this.$store.dispatch('systems/setActiveProduct', { groupId: group.id, id: null });
      });
      this.currNumGroups = this.groups.length;
    },
    closeModal() {
      this.modal.new = false;
      this.modal.show = false;
    },
    selectProduct(groupId, id) {
      this.$store.dispatch('systems/setActiveProduct', { groupId, id });
    },
    selectNewProduct(selectionIndex) {
      this.newGroup.list.forEach((product, index) => {
        product.selected = index === selectionIndex;
      });
    },
    addNewProduct(name) {
      this.newGroup.list.push({ name, selected: false });
    },
    deleteNewProduct() {
      this.newGroup.list = this.newGroup.list.filter(product => !product.selected);
    },
    async fetchGroups() {
      const groupData = await this.$services.ProductService.getGroups();
      const sortedGroupData = groupData.sort((a, b) => a.position_number - b.position_number);
      await this.$store.dispatch('systems/setGroups', sortedGroupData);
    },
    async fetchProducts() {
      const productData = await this.$services.ProductService.getProducts();
      this.$store.dispatch('systems/setProducts', productData.results);
    },
    changeGroupsNum(event) {
      const newValue = event;
      if (!newValue || !this.selectGroups.length) { return; }
      if (this.isEditing) {
        this.$services.MessageService.warning('Сначала необходимо сохранить или отменить изменения');
        return;
      }
      this.currNumGroups = newValue;
      if (newValue > this.groups.length) {
        this.isEditing = true;
        this.newGroup.created = true;
        this.newGroup.list = [];
        this.newGroup.name = '';
      } else if (newValue === this.groups.length) {
        this.isEditing = false;
        this.newGroup.created = false;
        this.newGroup.name = '';
        this.newGroup.list = [];
      } else if (newValue < this.groups.length) {
        this.modal.type = 'group';
        this.modal.text = `Удаленные данные будет невозможно восстановить!
          Продолжить удаление группы ${this.groups[this.groups.length - 1].name}?`;
        this.modal.confirm = true;
        this.currNumGroups = this.selectGroups[1];
      }
    },
    editGroupName(id, event) {
      if (this.isEditing && this.editName.id !== null && this.editName.id !== id) {
        this.$services.MessageService.warning('Сначала необходимо сохранить или отменить изменения');
        return;
      }
      if (this.isEditing && this.editName.id === id && this.editName.oldName === event.target.value) {
        this.isEditing = false;
        this.$store.dispatch('systems/setGroupName', { id, newName: this.editName.oldName });
        return;
      }
      if (!this.isEditing && this.editName.id === null) {
        const groupToEdit = this.groups.find(group => group.id === id);
        this.isEditing = true;
        this.editName.id = id;
        this.editName.oldName = groupToEdit.name;
      }
      this.$store.dispatch('systems/setGroupName', { id, newName: event.target.value });
    },
    showModal(mode, num, name, groupId, itemId, newGroup = false) {
      if (this.isEditing && !newGroup) {
        this.$services.MessageService.warning('Сначала необходимо сохранить изменения');
        return;
      }
      this.modal.mode = mode;
      this.modal.num = num;
      this.modal.name = name;
      this.modal.group = groupId;
      this.modal.itemId = itemId;
      this.modal.new = newGroup;
      this.modal.show = true;
    },
    confirmDeleteProduct(groupId, newGroup = false) {
      if (this.activeProduct(groupId) === null) { return; }
      this.modal.type = 'product';
      this.modal.text = 'Удаленные данные будет невозможно восстановить! Удалить продукт?';
      this.modal.group = groupId;
      this.modal.new = newGroup;
      this.modal.confirm = true;
    },
    async deleteItem() {
      if (this.isEditing && !this.modal.new) {
        this.$services.MessageService.warning('Сначала необходимо сохранить изменения');
        return;
      }
      if (this.modal.new) {
        this.newGroup.list = this.newGroup.list.filter(product => !product.selected);
        return;
      }
      this.modal.confirm = false;
      try {
        if (this.modal.type === 'group') {
          const groupToDelete = this.groups[this.groups.length - 1];
          this.products.forEach(product => {
            if (product.product_group === groupToDelete.id) {
              this.$services.ProductService.deleteProduct(product.id);
            }
          });
          await this.$services.ProductService.deleteGroup(groupToDelete.id);
          await this.fetchGroups();
          await this.fetchProducts();
          this.currNumGroups--;
        } else if (this.modal.type === 'product') {
          const productToDelete = this.activeProduct(this.modal.group);
          await this.$services.ProductService.deleteProduct(productToDelete.id);
          await this.fetchProducts();
        }
      } catch (err) {
        console.log(err);
        if (this.modal.type === 'group') {
          this.$services.MessageService.error('Удаление невозможно – продукты данной группы используются в карточках объектов или партнеров');
        } else if (this.modal.type === 'product') {
          this.$services.MessageService.error('Удаление невозможно – продукт используется в карточках объектов или партнеров');
        }
      }
    },
    async saveChanges() {
      try {
        if (this.newGroup.created) {
          if (!this.newGroup.name) {
            this.$services.MessageService.warning('Заполните название группы');
            return;
          }
          const createdGroup = await this.$services.ProductService.addGroup({
            name: this.newGroup.name,
            direction: this.activeTab
          });
          await this.fetchGroups()
            .then(() => {
              this.newGroup.list.forEach(product => { this.addProduct(product.name, createdGroup.id) });
            });

          this.$services.MessageService.success('Данные успешно сохранены');
          this.newGroup.created = false;
          this.isEditing = false;
          this.newGroup.name = '';
        } else if (this.editName.id) {
          const groupToEdit = this.groups.find(group => group.id === this.editName.id);
          if (!groupToEdit.name) {
            this.$services.MessageService.warning('Заполните название группы');
            return;
          }
          await this.$services.ProductService.updateGroup({ ...groupToEdit }, groupToEdit.id);
          this.$services.MessageService.success('Данные успешно сохранены');
          this.isEditing = false;
          this.editName.id = null;
          this.editName.oldName = '';
        }
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при обновлении данных');
      }
    },
    async addProduct(name, groupId) {
      await this.$services.ProductService.addProduct({
        name,
        product_group: groupId
      });
      await this.fetchProducts();
    },
    discardChanges() {
      if (!this.isEditing) { return; }
      this.isEditing = false;
      this.newGroup.created = false;
      this.newGroup.name = '';
      this.newGroup.list = [];
      if (this.editName.id) {
        this.$store.dispatch('systems/setGroupName', { id: this.editName.id, newName: this.editName.oldName });
        this.editName.id = null;
        this.editName.oldName = '';
      }
    },
    productsByGroup(groupId) {
      return this.$store.getters['systems/getGroupProducts'](groupId);
    },
    activeProduct(groupId) {
      return this.$store.getters['systems/getActiveProduct'](groupId) || null;
    }
  },
  computed: {
    directions() {
      return this.$store.getters['directions/getDirections'];
    },
    groups() {
      return this.$store.getters['systems/getGroups'].filter(group => group.direction === this.activeTab);
    },
    products() {
      return this.$store.getters['systems/getProducts'];
    },
    selectGroups() {
      if (this.groups.length === 0) {
        return [this.groups.length, this.groups.length + 1];
      }
      return [this.groups.length - 1, this.groups.length, this.groups.length + 1];
    }
  }
}
</script>

<style scoped lang="scss">
.system-edit {
  position: relative;
  padding-top: 30px;

  &__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 31px;
  }

  &__subtitle {
    display: block;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;

    &--fw-700 {
      font-weight: 700;
    }

    &--mb-large {
      margin-bottom: 30px;
    }
  }

  &__btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #E5E5E5;
    border: 1px solid #BFBFBF;
    padding: 15px;
  }

  &__main {
    background-color: #F2F2F2;
    border: 1px solid #BFBFBF;
    padding: 20px 24px;
    flex-grow: 1;
  }

  &__caption {
    font-size: 16px;
    line-height: 18px;
    color: #4E4E4E;
    margin-bottom: 20px;
  }

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  &__radio {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__tab-container {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__label {
    padding-left: 29px;
    position: relative;
    line-height: 20px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      border: 1px solid #4E4E4E;
      background-color: rgba(0, 0, 0, 0);
    }

    .system-edit__radio:checked+&::before {
      background-color: rgba(0, 0, 0, 1);
    }
  }

  &__panel {
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  &__section {
    width: 733px;
    min-width: 550px;

    &:not(:last-child) {
      margin-right: 80px;
    }
  }

  &__group-name {
    background-color: #fff;
    border: 1px solid #bfbfbf;
    padding: 10px 4px;
    width: 356px;
    margin-bottom: 20px;
  }

  &__num-select {
    width: 85px;
    margin-bottom: 20px;
  }

  &__num {
    width: 40px;
  }
}

.users-table {
  margin: 0 0 10px;
}

.users-table__head,
.users-table tr:nth-child(odd) {
  background-color: #fff;
}

.mr-15:not(:last-child) {
  margin-right: 15px;
}

.mr-10:not(:last-child) {
  margin-right: 10px;
}

.users-table tr.selected {
  background-color: #58BCAF;
  color: #fff;
}
</style>
