<template>
    <div class="ds-pagination">
        <button @click="prevPage()" :class="arrowDisabledLeft">
            <img src="../../src/assets/images/svg/chevron-left.svg" alt="">
        </button>
        <button v-for="(item, index) in shortList" :key="index" :data-num="item.num"
            :class="[{ 'ds-pagination__item ds-pagination__item--m': true }, { 'ds-pagination__item--active': current === item.num }]"
            @click="changeCurrent(item.num)">
            <span class="ds-pagination__item__text">
                {{ item.label }}
            </span>
        </button>
        <button @click="nextPage()" :class="arrowDisabledRight">
            <img src="../../src/assets/images/svg/chevron-right.svg" alt="">
        </button>
    </div>
</template>

<script lang="js">
import _ from 'lodash';

export default {
    name: 'PagePagination',
    data() {
        return {
            current: this.num,
            skipSymbol: '...'
        };
    },
    props: {
        count: {
            type: Number,
            default: 10
        },
        num: {
            type: Number,
            default: 1
        }
    },
    watch: {
        count() {
            this.current = 1;
        }
    },
    computed: {
        shortList() {
            const pages = _.range(1, this.count + 1);
            if (this.count <= 7) return this.mappingPages(pages);
            const pagesShort = pages.filter((num) => {
                if (num === 1 || num === this.count) return true;
                if (this.current - 1 <= num && this.current + 1 >= num) return true;
                if (num === 2) return this.current === 4 || this.current >= this.count - 2;
                if (num === 3) return this.current === 1 || this.current >= this.count - 1;
                if (num === this.count - 1) return this.current <= 3 || this.current === this.count - 3;
                if (num === this.count - 2) return this.current <= 2 || this.current === this.count;
                return false;
            });
            return this.mappingPages(pagesShort);
        },
        arrowDisabledLeft() {
            return {
                'ds-pagination__arrow': true,
                'ds-pagination__arrow--m': true,
                'ds-pagination__arrow-disabled': this.current === 1
            };
        },
        arrowDisabledRight() {
            return {
                'ds-pagination__arrow': true,
                'ds-pagination__arrow--m': true,
                'ds-pagination__arrow-disabled': this.current === this.count
            };
        }
    },
    methods: {
        mappingPages(arrPages) {
            const newArr = [];
            arrPages.forEach((num, index, arr) => {
                newArr.push({
                    label: num,
                    num
                });
                const nextNum = arr[index + 1];
                if (num + 1 !== nextNum && nextNum !== undefined) {
                    const skipNum = Math.floor((num + nextNum) / 2);
                    newArr.push({
                        label: this.skipSymbol,
                        num: skipNum
                    });
                }
            });

            return newArr;
        },
        changeCurrent(num) {
            let number = Number(num);
            if (_.isNaN(number)) return;
            if (number < 1) {
                number = 1;
            }
            if (number > this.count) {
                number = this.count;
            }
            if (this.current === number) return;
            this.current = number;
            this.$emit('changeCurrent', this.current);
        },
        prevPage() {
            this.changeCurrent(this.current - 1);
        },
        nextPage() {
            this.changeCurrent(this.current + 1);
        }
    }
}
</script>

<style lang="scss" scoped>
.ds-pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &__item {
        background-color: transparent;
        border: none;
        cursor: pointer;
        line-height: normal !important;
        position: relative;
        transition: color .15s ease;
        width: 32px;

        &--m {
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            height: 40px;
            min-width: 40px;
            padding: 8px;
        }

        &__text {
            position: relative;
        }

        &:hover {
            border: 1px solid #000000;
        }

        &:before {
            background-color: #000000;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform: scale(0);
            transition: transform ease;
        }

        &--active {
            color: #fff;

            &:before {
                transform: scale(1);
            }
        }
    }

    &__arrow {
        background-color: transparent;
        border: none;
        cursor: pointer;
        line-height: normal !important;
        position: relative;
        transition: color .15s ease;
        width: 32px;

        &--m {
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            height: 40px;
            min-width: 40px;
            padding: 8px;
        }

        &:hover {
            border: 1px solid #000000;
        }

        &:before {
            background-color: #000000;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform: scale(0);
            transition: transform ease;
        }
    }

    &__arrow-disabled {
        color: #a9a9a9;
        pointer-events: none;
    }
}
</style>
