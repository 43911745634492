import state from './BusinessRegionState';
import mutations from './BusinessRegionMutations';
import actions from './BusinessRegionActions';
import getters from './BusinessRegionGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
