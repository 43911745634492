<template>
  <div>
    <Dialog title="Добавить соцсеть" :visible.sync="isVisible" @closed="close()">
      <div class="form-item">
        <label class="form-label">Название соцсети</label>
        <div class="form-select-wrapper">
          <template>
            <Select v-model="socialId" collapse-tags placeholder="Название соцсети" class="form-select">
              <Option v-for="social in socials" :key="social.id" :label="social.name" :value="social.id">
              </Option>
            </Select>
          </template>
        </div>
      </div>
      <div class="form-item">
        <label class="form-label" for="link">Ссылка URL</label>
        <input id="link" class="form-input" type="url" v-model="url">
        <p class="text-small">Внести адрес с протоколом https://</p>
      </div>
      <div class="form-item">
        <p class="form-label">Иконка</p>
        <div class="social-icon">
          <img :src="selectedSocialLogo()" alt="">
        </div>
      </div>
      <div class="add-socials__footer">
        <button class="btn btn-top btn-green" @click="save">Сохранить</button>
        <button class="btn btn-top btn-gray" @click="close">Закрыть</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Select, Option, Dialog } from 'element-ui';

export default {
  name: 'AddSocials',
  components: {
    Select,
    Option,
    Dialog
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      isVisible: false,
      socialId: null,
      url: null
    }
  },
  watch: {
    visible(newVal) {
      this.isVisible = newVal;
    }
  },
  methods: {
    save() {
      if (!this.socialId || !this.url) { return; }
      this.$emit('save', { id: this.socialId, link: this.url });
      this.clear();
    },
    close() {
      this.isVisible = false;
      this.$emit('close');
      this.clear();
    },
    clear() {
      this.socialId = '';
      this.url = '';
    },
    selectedSocialLogo() {
      const socialsMap = this.socialsMap;
      const social = socialsMap instanceof Map ? socialsMap.get(this.socialId) : null;
      return social && social.image_link ? social.image_link : '';
    }
  },
  computed: {
    socials() {
      return this.$store.getters['social/getSocials'];
    },
    socialsMap() {
      return this.$store.getters['social/getSocialsMap'];
    }
  }
}
</script>

<style scoped>
.btn-top {
  margin: 0 7.5px;
}

.form-item {
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.form-input-check[type="checkbox"]:checked+.form-label-check::after {
  opacity: 1;
}

.form-input-radio[type="radio"]:checked+.form-label-radio::after {
  opacity: 1;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.el-select .el-input__suffix {
  background: #000000;
}

.el-select .el-input .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-icon-arrow-up:before {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-input__prefix,
.el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__inner {
  padding-left: 10px;
  padding-right: 0;
  border-radius: 0;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #000000;
}

.el-select .el-input__inner:focus {
  border-color: #000000;
}

.socials-table th,
td {
  text-align: left;
  padding: 15px;
}

.socials-table tr.socials-table__head {
  background: transparent;
}

.socials-table tr {
  background: #FFFFFF;
}

.socials-table tr:hover {
  background: #E5E5E5;
}

.socials-table tr.socials-table__head:hover {
  background: transparent;
}

.socials-table th {
  border-bottom: 1px solid #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.socials-table td {
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.galery__item img {
  object-fit: cover;
}
</style>

.text-small {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}
