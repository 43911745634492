import axios from '@/axios';
import Service from './Service';

const qs = require('qs');

export default class ObjectService extends Service {
  constructor(name = 'ObjectService', namespace = null, ...args) {
    super(name, namespace);
  }

  async addObjectType(dto) {
    await axios.post('/api/v1/object_types', dto);
  }

  async getObjectTypes(params) {
    const response = await axios.get('/api/v1/object_types', { params });
    return response.data;
  }

  async updateObjectType(objType, id) {
    return axios.patch(`/api/v1/object_types/${id}`, objType);
  }

  async deleteObjectType(id) {
    await axios.delete(`/api/v1/object_types/${id}`)
  }

  async getObjectType(id) {
    const response = await axios.get(`/api/v1/object_types/${id}`);
    return response.data;
  }

  async addObject(dto) {
    await axios.post('/api/v1/objects', dto);
  }

  async getObjects(params) {
    const response = await axios.get('/api/v1/objects', {
      params: {
        ...params,
        is_admin_panel: true
      },
      paramsSerializer: par => qs.stringify(par, { arrayFormat: 'repeat' })
    });
    return response.data;
  }

  async updateObject(object, id) {
    return axios.patch(`/api/v1/objects/${id}`, object);
  }

  async deleteObject(id) {
    await axios.delete(`/api/v1/objects/${id}`)
  }

  // Удаление объектов
  async deleteObjects(ids) {
    await axios.delete('/api/v1/objects', { data: { ids } });
  }

  async getObject(id) {
    const response = await axios.get(`/api/v1/objects/${id}`);
    return response.data;
  }
}
