import axios from '@/axios';
import Service from './Service';

export default class CountryService extends Service {
  constructor(name = 'CountryService', namespace = null, ...args) {
    super(name, namespace);
  }

  async addCountry(dto) {
    await axios.post('/api/v1/countries', dto);
  }

  async getCountries(params) {
    const response = await axios.get('/api/v1/countries', { params });
    return response.data;
  }

  async updateCountry(objType, id) {
    return axios.patch(`/api/v1/countries/${id}`, objType);
  }

  async deleteCountry(id) {
    await axios.delete(`/api/v1/countries/${id}`)
  }

  async getCountry(id) {
    const response = await axios.get(`/api/v1/countries/${id}`);
    return response.data;
  }
}
