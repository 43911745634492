import axios from '@/axios';
import Service from './Service';

const qs = require('qs');

export default class UserService extends Service {
    constructor(name = 'UserService', namespace = null, ...args) {
        super(name, namespace);
    }

    async addUser(data) {
        await axios.post('/api/v1/users', data);
    }

    async getUsers(params) {
      const response = await axios.get('/api/v1/users', {
        params: {
          ...params,
          all_rows: true
        },
        paramsSerializer: par => qs.stringify(par, { arrayFormat: 'repeat' })
      });
      return response.data;
    }

    async updateUser(id, data) {
        return axios.patch(`/api/v1/users/${id}/`, data);
    }

    async changeMyPassword(data) {
      return axios.post('/api/v1/users/me', data);
    }

    async deleteUser(id) {
        // const data = { is_active: false };
        // return axios.patch(`/api/v1/users/${id}/`, data);
        await axios.delete(`/api/v1/users/${id}/`);
    }

    async getUser(id) {
      const response = await axios.get(`/api/v1/users/${id}/`);
      return response.data;
    }

    async getMe() {
      const response = await axios.get('/api/v1/users/me');
      return response.data;
    }
}
