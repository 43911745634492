<template>
    <div class="ds-full-pagination">
        <div class="ds-full-pagination__per-page">
            <PerPageSelectPagination :perPage="perPage" @currentPerPage="$emit('currentPerPage', $event)" />
        </div>
        <div v-if="pagesCount > 1" class="ds-full-pagination__pagination">
            <PagePagination :count="pagesCount" :num="num" @changeCurrent="changeCurrent" />
        </div>
    </div>
</template>

<script lang="js">
import PagePagination from './PagePagination.vue';
import PerPageSelectPagination from './PerPageSelectPagination.vue';

export default {
    name: 'DsFullPagination',
    components: { PagePagination, PerPageSelectPagination },
    props: {
        pagesCount: {
            type: Number,
            default: 1
        },
        num: {
            type: Number,
            default: 1
        },
        perPage: {
            type: String,
            default: '10'
        }
    },
    methods: {
        changeCurrent(itemCount) {
            this.$emit('changeCurrent', itemCount);
        }
    }
}
</script>

<style scoped lang="scss">
.ds-full-pagination {
    display: flex;
    gap: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;

    &__pagination {
        align-items: center;
        display: flex;
        gap: 20px;
        margin-left: auto;
    }

    &__per-page {
        min-width: 260px;
    }
}
</style>
