<template>
  <div>
    <Dialog :title="text" :visible.sync="show" @closed="closePopup">
      <div class="confirm__buttons">
        <button class="confirm__btn confirm__btn_pink btn btn-small" @click="confirm">Да</button>
        <button class="confirm__btn btn btn-small btn-gray-border" @click="closePopup">Нет</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';

export default {
  name: 'ConfirmPopup',
  components: {
    Dialog
  },
  props: {
    show: Boolean,
    text: String
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    }
  }
}
</script>

<style scoped lang="scss">
.confirm {
  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__btn {
    height: 40px;
    min-width: 80px;
    padding: 6px 13px 10px;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &_pink {
      background-color: #DC143C;
      color: #fff;
    }
  }
}
</style>
