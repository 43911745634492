import state from './CountryState';
import mutations from './CountryMutations';
import actions from './CountryActions';
import getters from './CountryGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
