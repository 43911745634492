import state from './FacilityState';
import mutations from './FacilityMutations';
import actions from './FacilityActions';
import getters from './FacilityGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
