import Constants from '../../../constants/Constants';

export default {
    getPerPage: (state) => state.perPage,
    getPage: (state) => state.page,
    getSorting: (state) => state.sorting,
    getRoles: (state) => state.roles,
    getRole: (state) => (roleId) => state.roles.find((role) => role.id === roleId),
    getAdminRoleId: (state) => {
      const admin = state.roles.find((role) => role.code === Constants.USER_ROLE_CODE.ADMIN);
      return admin ? admin.id : null;
    },
    getRegionalRoleId: (state) => {
      const regional = state.roles.find((role) => role.code === Constants.USER_ROLE_CODE.BUSINESS_REGION_EMPLOYEE);
      return regional ? regional.id : null;
    },
    getRoleByCode: (state) => (code) => state.roles.find((role) => role.code === code)
}
