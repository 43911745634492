import axios from '@/axios';

export default {
    async fetchRoles({ commit }) {
        const response = await axios.get('/api/v1/roles');
        commit('SET_ROLES', response.data);
    },

    async resetPaging({ commit }) {
      commit('SET_PAGE', 0);
      commit('SET_PER_PAGE', 10);
    }
}
