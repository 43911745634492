export default {
  SET_SORTING(state, sorting) {
    state.sorting = sorting;
  },
  SET_PARTNER_TYPES(state, types) {
    state.partnerTypes = types;
  },
  SET_PARTNER_ATTRIBUTES(state, attrs) {
    state.partnerAttributes = attrs;
  }
}
