import axios from '@/axios';
import $store from '@/store/index';
import $router from '@/router';

const ResponseInterceptor = {
  install(Vue) {
    const vm = Vue.prototype;
    axios.interceptors.response.use((response) => response, (error) => {
      const { response } = error;
      const msg = (response && response.detail && response.detail.msg) ? response.detail.msg : 'Произошла ошибка запроса к серверу';
      if (response && response.status === 401) {
          $store.dispatch('auth/dropAuth').then(_ => window.location.reload());
      } else if (response && response.status === 403) {
        $router.go(-1);
        vm.$services.MessageService.error(msg);
      } else if (response.status === 500) {
        vm.$services.MessageService.error(msg);
      }
      return Promise.reject(response);
    })
  }
};

export default ResponseInterceptor;
