import qs from 'qs';
import axios from '@/axios';
import AuthHelper from '@/helpers/AuthHelper';

export default {
  async login({ commit }, item) {
    await axios({
      method: 'post',
      url: '/api/v1/auth/login',
      withCredentials: true,
      data: qs.stringify({
        username: item.username,
        password: item.password
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    });
    const accessToken = AuthHelper.checkAuth();
    if (accessToken) {
      const response = await axios.get('/api/v1/users/me');
      commit('SET_AUTH_USER', response.data);
    }
  },

  async logout({ commit }) {
    await axios.post('/api/v1/auth/logout');
    commit('REMOVE_AUTH');
  },

  async dropAuth({ commit }) {
    commit('REMOVE_AUTH');
  },

  async forgotPassword({ commit }, item) {
    await axios.post('/api/v1/auth/forgot-password', item);
  },

  async resetPassword({ commit }, item) {
    await axios.post('/api/v1/auth/reset-password', item);
  },

  async fetchAuthUser({ commit }) {
    const accessToken = AuthHelper.checkAuth();
    if (accessToken) {
      const response = await axios.get('/api/v1/users/me');
      commit('SET_AUTH_USER', response.data);
    }
  }
}
