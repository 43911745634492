import Vue from 'vue';
import Vuex from 'vuex';
import moduleAuth from './modules/auth';
import moduleUser from './modules/user';
import moduleBusinessRegion from './modules/business-region';
import moduleFacility from './modules/facility';
import modulePartner from './modules/partner';
import moduleSocial from './modules/social';
import moduleSystems from './modules/systems';
import moduleDirections from './modules/directions';
import moduleGeoRegion from './modules/geo-region';
import moduleCountry from './modules/country';
import moduleMap from './modules/map';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth: moduleAuth,
        user: moduleUser,
        business_region: moduleBusinessRegion,
        facility: moduleFacility,
        partner: modulePartner,
        social: moduleSocial,
        directions: moduleDirections,
        geo_region: moduleGeoRegion,
        country: moduleCountry,
        systems: moduleSystems,
        map: moduleMap
    }
})
