import state from './UserState';
import mutations from './UserMutations';
import actions from './UserActions';
import getters from './UserGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
