import axios from '@/axios';
import Service from './Service';

export default class ImportDataService extends Service {
  constructor(name = 'ImportDataService', namespace = null, ...args) {
    super(name, namespace);
  }

  async uploadFile({ uploadType, file }) {
    // eslint-disable-next-line func-names
    const fileData = new FormData();
    fileData.append('file_obj', file, file.name);
    return axios.post(`/api/v1/migrate_data?upload_type=${uploadType}`, fileData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }
}
