import axios from '@/axios';
import Service from './Service';

export default class SocialsService extends Service {
    constructor(name = 'SocialsService', namespace = null, ...args) {
        super(name, namespace);
    }

  async addSocial(dto) {
    await axios.post('/api/v1/social_networks', dto);
  }

  async getSocials(params) {
    const response = await axios.get('/api/v1/social_networks', { params });
    return response.data;
  }

  async updateSocial(objType, id) {
    return axios.patch(`/api/v1/social_networks/${id}`, objType);
  }

  async deleteSocial(id) {
    await axios.delete(`/api/v1/social_networks/${id}`)
  }

  async getSocial(id) {
    const response = await axios.get(`/api/v1/social_networks/${id}`);
    return response.data;
  }
}
