<template>
  <div class="user-page-wrapper">
    <ConfirmPopup :show="confirm" text="Есть несохраненные данные. Вернуться на страницу Бизнес-регионы?"
      @close="confirm = false" @confirm="closeRegion"></ConfirmPopup>
    <div class="g-container">
      <div class="user-create">
        <h1 class="title" v-if="mode === 'create'">Новый бизнес - регион</h1>
        <h1 class="title" v-if="mode === 'edit'">{{ regionName }}</h1>
        <div class="btn-wrapper">
          <button class="btn btn-top btn-green" @click="saveRegion">Сохранить</button>
          <button class="btn btn-top btn-gray" @click="confirmClose">Закрыть</button>
        </div>
      </div>
      <div class="create">
        <form class="form">
          <div class="form-item">
            <p class="text">Внимание! Поля обозначенные знаком * обязательны к заполнению!</p>
          </div>
          <div class="form-item form-item--parameters">
            <label class="form-label" for="region-name">Название бизнес-региона *</label>
            <input @focus="nameUntouched = false" :class="{ 'error': !!errorMessages.regionName }" class="form-input"
              type="text" id="region-name" v-model="regionName">
            <p v-if="errorMessages.regionName" class="message-error">{{ errorMessages.regionName }}</p>
          </div>
          <div class="form-item">
            <p class="text text-bold">Направление*</p>
            <p v-if="errorMessages.direction" class="message-error">{{ errorMessages.direction }}</p>
          </div>
          <div class="form-item-wrapper">
            <div class="form-item" v-for="item in directions" :key="item.id">
              <input :id="item.id" class="form-input-radio" name="direction" type="radio" v-model="direction"
                :value="item.id">
              <label :for="item.id" class="form-label form-label-radio">
                {{ item.name }}
              </label>
            </div>
          </div>
          <div class="form-item geo-region-select">
            <p class="form-label">Географический регион*</p>
            <template>
              <el-select class="geo-region-select" multiple filterable readonly="false"
                placeholder="Выберите или введите название" required="true" :value="['Выберите или введите название']"
                :class="{ 'error': errorMessages.geoRegions }" @change="updateChosenRegions($event)">
                <el-option v-for="(region, index) in geoRegions" :key="index" :value="region.name" :label="region.name"
                  class="geo-region-select__option" :class="{ 'active': region.selected }">
                  <span class="geo-region-select__item">
                    <span>{{ region.cladr_code }}</span>
                    <span>{{ region.name }}</span>
                    <span>{{ region.country_name }}</span>
                  </span>
                </el-option>
              </el-select>
            </template>
            <p v-if="errorMessages.geoRegions" class="message-error">{{ errorMessages.geoRegions }}</p>
            <div class="region-table-wrap" v-if="selectedRegions.length">
              <table class="region-table">
                <thead>
                  <tr>
                    <th>
                      <div class="region-title">
                        <p class="form-label">Код КЛАДР/ОКСМ</p>
                        <img class="region-list__icon" src="../../assets/images/svg/down.svg" alt=""
                          :class="{ 'region-list__icon-rotate': geoRegionsSort === 'desc' }">
                      </div>
                    </th>
                    <th>
                      <div class="region-title">
                        <p class="form-label">Название географического региона</p>
                        <img class="region-list__icon" src="../../assets/images/svg/down.svg" alt=""
                          :class="{ 'region-list__icon-rotate': geoRegionsSort === 'desc' }">
                      </div>
                    </th>
                    <th>
                      <div class="region-title">
                        <p class="form-label">Страна</p>
                        <img class="region-list__icon" src="../../assets/images/svg/down.svg" alt=""
                          :class="{ 'region-list__icon-rotate': geoRegionsSort === 'desc' }">
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="region in selectedRegions" :key="region.name">
                    <td>
                      <div class="region-list__item">
                        <span>{{ region.cladr_code }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="region-list__item">
                        <span>{{ region.name }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="region-list__item">
                        <span>{{ region.country_name }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="cursor-pointer">
                        <img width="15px" class="region-list__close-icon" src="@/assets/images/svg/close.svg"
                          alt="удалить географический регион" @click="region.selected = false">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmPopup from '@/components/ConfirmPopup.vue';

export default {
  name: 'RegionCreate',
  components: {
    ConfirmPopup
  },
  data() {
    return {
      mode: 'create',
      confirm: false,
      regionId: '',
      regionName: '',
      directions: [],
      direction: '',
      geoRegions: [],
      countries: [],

      nameUntouched: true,
      directionUntouched: true,
      regionsUntouched: true,

      errorMessages: {
        regionName: null,
        direction: null,
        geoRegions: null
      },
      geoRegionsSort: 'asc'
    }
  },
  watch: {
    regionName: {
      handler(newValue) {
        if (this.nameUntouched) {
          return;
        }

        if (!newValue) {
          this.errorMessages.regionName = 'Это поле обязательно к заполнению';
        } else {
          this.nameUntouched = false;
          this.regionName = newValue;
          this.errorMessages.regionName = '';
        }
      }
    },
    direction: {
      handler(newValue) {
        if (!newValue) {
          this.errorMessages.regionName = 'Это поле обязательно к заполнению';
        } else {
          this.directionUntouched = false;
          this.direction = newValue;
          this.errorMessages.direction = '';
        }
      }
    },
    selectedRegions: {
      handler(newValue) {
        if (!newValue.length && !this.regionsUntouched) {
          this.errorMessages.geoRegions = 'Выберите хотя бы один географический регион';
        } else {
          this.errorMessages.geoRegions = null;
        }
      }
    }
  },
  async created() {
    const geoRegions = await this.fetchGeoRegions();
    this.geoRegions = geoRegions.results.map(region => ({
      ...region,
      selected: false
    }));
    const directionData = await this.fetchDirections();
    this.directions = directionData.results;

    if (this.$route.name === 'region-edit' && this.$route.params.id) {
      this.mode = 'edit';
      const region = await this.fetchBusinessRegion(this.$route.params.id);
      this.regionId = region.id;
      this.regionName = region.name;
      this.direction = region.direction_id;
      setTimeout(() => {
        this.directionUntouched = true;
      }, 100);
      region.geo_regions.forEach(geoRegion => {
        this.geoRegions.find(item => item.id === geoRegion).selected = true;
      });
    }
  },
  methods: {
    saveRegion() {
      this.regionsUntouched = false;
      this.nameUntouched = false;
      if (!this.dataIsValid()) {
        this.$services.MessageService.warning('Заполните все обязательные поля');
        return;
      }

      const region = {
        name: this.regionName,
        direction: this.direction,
        geo_regions: this.selectedRegions.map(geoRegion => geoRegion.id)
      }

      if (this.mode === 'create') {
        this.createRegion(region);
      } else if (this.mode === 'edit') {
        this.patchRegion(region);
      }
    },
    async createRegion(region) {
      try {
        await this.$services.RegionService.addBusinessRegion(region);
        this.$services.MessageService.success('Бизнес-регион добавлен');
        setTimeout(() => {
          this.closeRegion();
        }, 2000);
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Данные недействительны');
      }
    },
    async patchRegion(region) {
      try {
        await this.$services.RegionService.updateBusinessRegion(region, this.regionId);
        this.$services.MessageService.success('Бизнес-регион обновлен');
        setTimeout(() => {
          this.closeRegion();
        }, 2000);
      } catch (e) {
        if (e.data && e.data.detail) {
          this.$services.MessageService.error(`Данные недействительны: ${e.data.detail[0].msg}`);
        }
      }
    },
    async fetchGeoRegions() {
      return this.$services.RegionService.getGeoRegions({ page_size: 10000, ordering: 'name' });
    },
    async fetchDirections() {
      return this.$services.DirectionService.getDirections({ page_size: 10000 });
    },
    async fetchBusinessRegion(id) {
      return this.$services.RegionService.getBusinessRegion(id);
    },
    dataIsValid() {
      if (!this.regionName) {
        this.errorMessages.regionName = 'Это поле обязательно к заполнению';
      }
      if (!this.direction) {
        this.errorMessages.direction = 'Это поле обязательно к заполнению';
      }
      if (!this.selectedRegions.length) {
        this.errorMessages.geoRegions = 'Выберите хотя бы один географический регион';
      }

      return !(this.errorMessages.regionName || this.errorMessages.direction || this.errorMessages.geoRegions);
    },
    confirmClose() {
      if (this.nameUntouched && this.directionUntouched && this.regionsUntouched) {
        this.closeRegion();
      } else {
        this.confirm = true;
      }
    },
    closeRegion() {
      this.clearData();
      this.$router.push({ path: '/regions', name: 'regions-list' });
    },
    updateChosenRegions(value) {
      const regionToUpdate = this.geoRegions.find(region => region.name === value[1]);
      if (regionToUpdate) {
        regionToUpdate.selected = !regionToUpdate.selected;
        this.regionsUntouched = false;
      }
    },
    clearData() {
      this.regionsUntouched = true;
      this.nameUntouched = true;
      this.regionName = '';
      this.direction = 0;
      this.geoRegions.forEach(region => {
        region.selected = false;
      });
      this.errorMessages.regionName = null;
      this.errorMessages.geoRegions = null;
    }
  },
  computed: {
    businessRegions() {
      return this.$store.getters['business_region/getRegions'];
    },
    adminRoleId() {
      return this.$store.getters['user/getAdminRoleId'];
    },
    authUserIsAdmin() {
      const authUser = this.authUser;
      return authUser && this.authUser.role_id === this.adminRoleId;
    },
    selectedRegions() {
      return this.geoRegions.filter(region => region.selected);
    }
  }
}
</script>

<style scoped>
.user-create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.title {
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
}

.btn-wrapper {
  display: flex;
  padding: 15px 7.5px;
  justify-content: space-between;
  align-items: center;
  background: #E5E5E5;
  border: 1px solid #BFBFBF;
  border-top: 0;
}

.btn-top {
  margin: 0 7.5px;
}

.create {
  border: 1px solid #BFBFBF;
}

.form {
  padding: 20px 25px 80px 25px;
  background: #F2F2F2;
  width: 100%;
  min-height: 800px;
}

.form-item--parameters {
  width: 356px;
}

.text {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}

.text-small {
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}

.form-item {
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.message-error {
  color: #DC143C;
  font-size: 14px;
  line-height: 17px;
}

.form-item-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.form-item-wrapper .form-item {
  margin-right: 20px;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 0;
}

.text-small {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}

.text-bold {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.message-error {
  color: #DC143C;
  font-size: 14px;
  line-height: 17px;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-label-radio {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-radio::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #4E4E4E;
}

.form-label-radio::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #000000;
  border: 1px solid #4E4E4E;
  z-index: 2;
  opacity: 0;
}

.form-input-radio {
  display: none;
}

.form-input-radio[type="radio"]:checked+.form-label-radio::after {
  opacity: 1;
}

.region-title {
  display: flex;
  align-items: baseline;
  padding-left: 10px;
}

.region-table-wrap {
  width: 733px;
  margin-top: 15px;
}

.region-table {
  width: 100%;
}

.region-table thead tr {
  border-bottom: 2px solid #000000;
  background: transparent;
}

.region-table tbody tr {
  transition: all .3s ease-in-out;
  background: #FFFFFF;
  cursor: pointer;
}

.region-table tbody tr:nth-child(even) {
  background-color: #e5e5e5;
}

.region-table tbody tr:hover {
  background: #E5E5E5;
}

.region-list__item {
  font-size: 18px;
  line-height: 24px;
  background: transparent;
  padding: 13px 10px;
}

.region-list__icon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.region-list__icon-rotate {
  transform: rotate(180deg);
}

.region-list__item .form-label-check span {
  margin-right: 15px;
}

.geo-region-select {
  width: 468px;
  position: relative;
  margin-bottom: 5px;
}

.geo-region-select__option {
  position: relative;
  padding: 9px 20px 9px 49px;
  font-size: 18px;
  line-height: 24px;
}

.geo-region-select__item {
  display: flex;
}

.geo-region-select__item span:first-child {
  width: 132px;
  margin-right: 10px;
}

.geo-region-select__item span:nth-child(2) {
  width: 170px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.geo-region-select__option::before {
  content: '';
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 19px;
  height: 19px;
  border: 1px solid #000;
  background-color: #fff;
}

.geo-region-select__option::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 24px;
  width: 12px;
  height: 12px;
  background: url("../../assets/images/svg/checked-black.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.geo-region-select__option.active::after {
  opacity: 1;
}
</style>
