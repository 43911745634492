<template>
  <div>
    <Dialog :title="title" :visible.sync="isVisible" @closed="close">
      <form @submit.prevent="save">
        <div class="form-item">
          <label class="form-label" for="system-name">Название *</label>
          <input id="system-name" class="form-input" type="text" :value="name"
            @input="enteredName = $event.target.value">
        </div>
        <div class="form-item" v-if="itemType === 'partner-types' || itemType === 'partner-attributes'">
          <label class="form-label">Направление</label>
          <template>
            <el-select :value="direction" filterable @change="selectedDirection = $event"
              placeholder="Выберите направление" class="form-select">
              <el-option v-for="direction in directions" :key="direction.id" :label="direction.name"
                :value="direction.id">
              </el-option>
            </el-select>
          </template>
        </div>
        <div class="form-item" v-if="itemType === 'socials' || itemType === 'partner-types'">
          <input type="file" class="form-file" id="file-upload" @change="uploadFile($event)">
          <label for="file-upload" class="btn btn-top btn-gray-border btn-small form-btn">
            Загрузить новую иконку
          </label>
        </div>
        <div class="form-item" v-if="itemType === 'directions'">
          <label class="form-label" for="system-name-en">Английское название *</label>
          <input id="system-name-en" class="form-input" type="text" :value="nameEn"
            @input="enteredNameEn = $event.target.value">
        </div>
        <div class="form-item" v-if="itemType === 'directions'">
          <label class="form-label" for="system-code">Короткий код *</label>
          <input id="system-code" class="form-input" type="text" :value="code"
            @input="enteredCode = $event.target.value">
        </div>
        <div class="form-item" v-if="itemType === 'directions'">
          <label class="form-label" for="system-position-new">Позиция</label>
          <div class="form-numeric-input-wrapper">
            <div class="btn-adjust btn-adjust__minus" :class="position <= 1 ? 'btn-adjust__disabled' : ''"
              @click="adjustValue(-1)"></div>
            <input id="system-position-new" class="form-input" style="margin-left: 8px; margin-right: 8px;"
              type="number" :value="position" :min="0" :max="100" :step="1"
              @input="enteredPosition = $event.target.value">
            <div class="btn-adjust btn-adjust__plus" @click="adjustValue(1)"
              :class="position >= 100 ? 'btn-adjust__disabled' : ''"></div>
          </div>
        </div>
        <div class="form-item" v-if="itemType === 'directions'">
          <label class="form-label" for="color-picker">Цвет</label>
          <div class="form-color-picker-wrapper">
            <!-- Кнопка для открытия color picker -->
            <div class="color-preview" :style="{ backgroundColor: color }" @click="triggerColorPicker"></div>
            <input id="color-picker" class="form-input" type="text" disabled :value="color">
            <!-- Скрытый input для выбора цвета -->
            <input id="color-picker" type="color" class="color-input" v-model="enteredColor" @input="updateColor"
              ref="colorInput" />
          </div>
        </div>
        <div class="add-socials__footer">
          <button type="submit" class="btn btn-top btn-green btn-small">Сохранить</button>
          <button class="btn btn-top btn-gray btn-small" @click="close">Закрыть</button>
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import ValidationHelper from '@/helpers/ValidationHelper';

export default {
  name: 'AddItem',
  components: {
    Dialog
  },
  props: {
    visible: Boolean,
    mode: String,
    perPage: Number,
    itemType: String,
    itemName: String,
    initialName: String,
    initialImageId: String,
    itemId: {
      type: String,
      default: ''
    },
    initialLinkId: {
      type: String,
      default: ''
    },
    initialNameEn: {
      type: String,
      default: ''
    },
    initialCode: {
      type: String,
      default: ''
    },
    initialPosition: {
      type: Number,
      default: 1
    },
    initialColor: {
      type: String,
      default: '#FFFFFF'
    },
    initialDirection: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isVisible: false,
      enteredName: null,
      enteredNum: null,
      enteredLinkId: null,
      enteredNameEn: null,
      enteredCode: null,
      enteredPosition: null,
      enteredColor: null,
      selectedDirection: null,
      uploadedImageId: null
    }
  },
  watch: {
    visible(newVal) {
      this.isVisible = newVal;
    }
  },
  methods: {
    async uploadFile(event) {
      const file = event.target.files[0];
      const validationResult = ValidationHelper.imageIsValid(file);
      if (validationResult.isValid) {
        const id = await this.$services.FileUploadService.uploadFile(file);
        if (id) {
          this.uploadedImageId = id;
          this.$services.MessageService.success('Изображение успешно загружено');
        } else {
          this.$services.MessageService.error('Произошла ошибка при загрузке изображения');
        }
      } else {
        this.$services.MessageService.error(validationResult.errorMessage);
      }
    },
    save() {
      if (!this.name) { return; }
      if ((this.itemType === 'socials' || this.itemType === 'partner-types') && !this.imageId) { return; }
      if (this.itemType === 'directions' && (!this.nameEn || !this.code)) { return; }
      if ((this.itemType === 'partner-types' || this.itemType === 'partner-attributes') && !this.direction) { return; }
      if (this.mode === 'add') {
        this.addItem();
      } else if (this.mode === 'edit') {
        this.editItem();
      }
      this.close();
    },
    async addItem() {
      try {
        // НАПРАВЛЕНИЯ
        if (this.itemType === 'directions') {
          await this.$services.DirectionService.addDirection({
            name: this.name, name_en: this.nameEn, code: this.code, position: this.position, color: this.color
          });
          // ТИПЫ ОБЪЕКТОВ
        } else if (this.itemType === 'objects') {
          await this.$services.ObjectService.addObjectType({ name: this.name });
          // ТИПЫ ПАРТНЕРОВ
        } else if (this.itemType === 'partner-types') {
          await this.$services.PartnerService.addPartnerType({
            name: this.name,
            direction: this.direction,
            image_id: this.imageId,
            image_link: ''
          });
          // ПРИЗНАКИ ПАРТНЕРОВ
        } else if (this.itemType === 'partner-attributes') {
          await this.$services.PartnerService.addPartnerAttribute({ name: this.name, direction: this.direction });
          // ГЕОГРАФИЧЕСКИЕ РЕГИОНЫ
        } else if (this.itemType === 'geo-regions') {
          await this.$services.RegionService.addGeoRegion({ name: this.name });
          // СТРАНЫ
        } else if (this.itemType === 'countries') {
          await this.$services.CountryService.addCountry({ name: this.name });
          // СОЦИАЛЬНЫЕ СЕТИ
        } else if (this.itemType === 'socials') {
          await this.$services.SocialsService.addSocial({ name: this.name, image_id: this.imageId, image_link: '' });
        }
        await this.updateItems();
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при добавлении данных');
      }
    },
    async editItem() {
      try {
        // НАПРАВЛЕНИЯ
        if (this.itemType === 'directions') {
          const direction = this.$store.getters['directions/getDirection'](this.itemId);
          const data = {
            name: this.name,
            name_en: this.nameEn,
            code: this.code,
            position: this.position,
            color: this.color
          }
          await this.$services.DirectionService.updateDirection(data, this.itemId);
          // ТИПЫ ОБЪЕКТОВ
        } else if (this.itemType === 'objects') {
          await this.$services.ObjectService.updateObjectType({ name: this.name }, this.itemId);
          // ТИПЫ ПАРТНЕРОВ
        } else if (this.itemType === 'partner-types') {
          await this.$services.PartnerService.updatePartnerType(
            {
              name: this.name,
              direction: this.direction,
              image_id: this.imageId,
              image_link: ''
            },
            this.itemId
          );
          // ПРИЗНАКИ ПАРТНЕРОВ
        } else if (this.itemType === 'partner-attributes') {
          await this.$services.PartnerService.updatePartnerAttribute({ name: this.name, direction: this.direction }, this.itemId);
          // СТРАНЫ
        } else if (this.itemType === 'countries') {
          await this.$services.CountryService.updateCountry({ name: this.name }, this.itemId);
          // СОЦИАЛЬНЫЕ СЕТИ
        } else if (this.itemType === 'socials') {
          await this.$services.SocialsService.updateSocial({ name: this.name, image_id: this.imageId, image_link: '' }, this.itemId);
        }
        await this.updateItems();
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при изменении данных');
      }
    },
    async updateItems() {
      const params = {
        page_size: this.perPage,
        page: 0
      };
      // НАПРАВЛЕНИЯ
      if (this.itemType === 'directions') {
        const newData = await this.$services.DirectionService.getDirections(params);
        this.$store.dispatch('directions/setDirections', newData.results);
        // ТИПЫ ОБЪЕКТОВ
      } else if (this.itemType === 'objects') {
        const newData = await this.$services.ObjectService.getObjectTypes(params);
        this.$store.dispatch('facility/setFacilityTypes', newData.results);
        // ТИПЫ ПАРТНЕРОВ
      } else if (this.itemType === 'partner-types') {
        const newData = await this.$services.PartnerService.getPartnerTypes(params);
        this.$store.dispatch('partner/setPartnerTypes', newData.results);
        // ПРИЗНАКИ ПАРТНЕРОВ
      } else if (this.itemType === 'partner-attributes') {
        const newData = await this.$services.PartnerService.getPartnerAttributes(params);
        this.$store.dispatch('partner/setPartnerAttributes', newData.results);
        // СТРАНЫ
      } else if (this.itemType === 'countries') {
        const newData = await this.$services.CountryService.getCountries(params);
        this.$store.dispatch('country/setCountries', newData.results);
        // СОЦИАЛЬНЫЕ СЕТИ
      } else if (this.itemType === 'socials') {
        const newData = await this.$services.SocialsService.getSocials(params);
        this.$store.dispatch('social/setSocials', newData.results);
      }
    },
    close(e) {
      if (e) {
        e.preventDefault();
      }
      this.isVisible = false;
      this.enteredName = null;
      this.enteredNameEn = null;
      this.enteredLinkId = null;
      this.uploadedImageId = null;
      this.selectedDirection = null;
      this.enteredCode = null;
      this.enteredPosition = null;
      this.enteredColor = null;
      this.$emit('close');
    },
    adjustValue(amount) {
      this.enteredPosition = Math.min(Math.max(this.position + amount, 1), 100);
    },
    triggerColorPicker() {
      // Триггер скрытого input[type="color"]
      this.$refs.colorInput.click();
    },
    updateColor(event) {
      // Обновление цвета при выборе
      this.enteredColor = event.target.value; // Обновляем цвет
      this.$emit('color-changed', this.enteredColor); // Эмитим новое значение
    }
  },
  computed: {
    title() {
      return `${this.mode === 'add' ? 'Добавить' : 'Редактировать'} ${this.itemName}`;
    },
    name() {
      return this.enteredName === null ? this.initialName : this.enteredName;
    },
    nameEn() {
      return this.enteredNameEn === null ? this.initialNameEn : this.enteredNameEn;
    },
    code() {
      return this.enteredCode === null ? this.initialCode : this.enteredCode;
    },
    direction() {
      return this.selectedDirection === null ? this.initialDirection : this.selectedDirection;
    },
    position() {
      return this.enteredPosition === null ? this.initialPosition : this.enteredPosition;
    },
    color() {
      return this.enteredColor === null ? this.initialColor : this.enteredColor;
    },
    directions() {
      return this.$store.getters['directions/getDirections'];
    },
    imageId() {
      return this.uploadedImageId === null ? this.initialImageId : this.uploadedImageId;
    }
  }
}
</script>

<style scoped>
.btn-top:first-child {
  margin-right: 15px;
}

.form-item {
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.form-numeric-input-wrapper {
  display: flex;
  align-items: center;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-file {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-btn {
  display: inline-block;
}

.form-color-picker-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.color-preview {
  width: 35px;
  height: 32px;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 5px;
}

.color-input {
  position: absolute;
  top: 16px;
  left: 40px;
  /* Смещение вправо от кнопки */
  z-index: 100;
  opacity: 0;
  /* Скрываем стандартный input, чтобы пользователь кликал по кнопке */
  pointer-events: none;
  /* Отключаем клики */
}

.btn-adjust {
  background-color: #E50040;
  cursor: pointer;
  height: 32px;
  width: 39px;
  margin-bottom: 5px;

  background-size: 18px;
  background-repeat: no-repeat;
}

/* Для Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Для Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-adjust__plus {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48Zz48Zz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCA5aDlWMGgydjloOXYyaC05djlIOXYtOUgweiIvPjwvZz48L2c+PC9zdmc+);
  background-position: 50%;
}

.btn-adjust__minus {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCIgdmlld0JveD0iMCAwIDQ0IDQ0Ij48Zz48Zz48Zz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDMwdjRIMHoiLz48L2c+PC9nPjwvZz48L3N2Zz4=);
  background-position: 11px 16px;
}

.btn-adjust__disabled {
  background-color: #D9D9D9;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.add-socials__footer {
  margin-top: 30px;
}
</style>
