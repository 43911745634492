import mock from '@/fake-db/mock';
import Constants from '@/constants/Constants';

const facilityTypes = [
  {
    id: 1,
    name: 'Жилой комплекс'
  },
  {
    id: 2,
    name: 'Коттедж'
  },
  {
    id: 3,
    name: 'Оффисное здание'
  },
  {
    id: 4,
    name: 'Гостиница'
  },
  {
    id: 5,
    name: 'Ресторан'
  }
];

const testFacilities = {
    count: 2,
    next: true,
    previous: false,
    results: [
        {
            id: 1,
            name: 'ЖК «Жемчужина моря»',
            type: 'Жилой комплекс',
            is_active: true,
            map_visible: true,
            completion_year: 2018,
            address: 'NY XYZ Str.',
            code_KLADR: '123',
            code_FIAS: '1234567',
            email_first: '',
            email_second: '',
            geo_coordinates: {},
            url: '',
            social_networks: [],
            regions: [1],
            curators_primary: [{
              id: 273,
              full_name: 'Mary Petrov'
            }],
            curators_secondary: [{
              id: 272,
              full_name: 'REx S'
            }]
        },
        {
          id: 2,
          name: 'ЖК «Посейдон»',
          type: 'Жилой комплекс',
          is_active: true,
          map_visible: true,
          completion_year: 2019,
          address: 'NY XYZ Str.',
          code_KLADR: '123',
          code_FIAS: '1234567',
          email_first: '',
          email_second: '',
          geo_coordinates: {},
          url: '',
          social_networks: [],
          regions: [1, 2],
          curators_primary: [{
            id: 273,
            full_name: 'Mary Petrov'
          }],
          curators_secondary: [{
            id: 272,
            full_name: 'REx S'
          }]
        }
    ]
}

mock.onGet('/api/v1/facilities').reply((request) => new Promise(((resolve, reject) => {
  setTimeout(() => resolve([200, testFacilities]), 200);
})));

const facilityReg = new RegExp('/api/v1/facilities/\\d+');

mock.onGet(facilityReg).reply((request) => new Promise(((resolve, reject) => {
  setTimeout(() => {
    const id = parseInt(request.url.substring(request.url.lastIndexOf('/') + 1), 10);
    const facility = testFacilities.results.find((u) => u.id === id);
    const response = facility ? [200, JSON.parse(JSON.stringify(facility))] : [404];
    resolve(response);
    }, 500);
})));

mock.onGet('/api/v1/facility-types').reply((request) => new Promise(((resolve, reject) => {
  setTimeout(() => resolve([200, facilityTypes]), 200);
})));
