import axios from '@/axios';
import Service from './Service';

export default class DirectionService extends Service {
    constructor(name = 'DirectionService', namespace = null, ...args) {
        super(name, namespace);
    }

  async addDirection(dto) {
    await axios.post('/api/v1/directions', dto);
  }

  async getDirections(params) {
    const response = await axios.get('/api/v1/directions', { params });
    return response.data;
  }

  async updateDirection(direction, id) {
    return axios.patch(`/api/v1/directions/${id}`, direction);
  }

  async deleteDirection(id) {
    await axios.delete(`/api/v1/directions/${id}`)
  }

    async getDirection(id) {
      const response = await axios.get(`/api/v1/directions/${id}`);
      return response.data;
    }
}
