export default {
  USER_ROLE_CODE: {
    ADMIN: 'admin',
    CALL_CENTER_EMPLOYEE: 'call_center_employee',
    BUSINESS_REGION_EMPLOYEE: 'business_region_employee'
  },
  ERROR_CODES: {
    USER_NOT_EXIST: 'USER_NOT_EXIST'
  },
  IMPORT_DATA_OPTIONS: [{
    id: 'object',
    title: 'Объекты'
  }, {
    id: 'partner',
    title: 'Партнеры'
  }]
};
