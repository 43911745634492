import state from './SystemsState';
import mutations from './SystemsMutations';
import actions from './SystemsActions';
import getters from './SystemsGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
