<template>
  <div class="user-page-wrapper">
    <div class="g-container">
      <div class="user-create">
        <h1 class="title">Редактировать пользователя ( {{ user.username }} )</h1>
        <div class="btn-wrapper">
          <button class="btn btn-top btn-green" @click="updateUser">Сохранить</button>
          <button class="btn btn-top btn-gray" @click="closeUser">Закрыть</button>
        </div>
      </div>
      <div class="create">
        <div class="tabs">
          <div class="tab" :class="{ ' active': (tab === 0), active: (tab === 0) }" @click="tab = 0">Общая информация
          </div>
          <div class="tab" :class="{ ' active': (tab === 1), active: (tab === 1) }" @click="tab = 1">Параметры</div>
        </div>
        <template v-if="tab === 0">
          <form class="form">
            <div class="form-item">
              <p class="text">
                Внимание! Поля обозначенные знаком * обязательны к заполнению!
              </p>
            </div>
            <div class="tab-content">
              <div class="content-item">
                <div class="form-item">
                  <label class="form-label">Имя пользователя*</label>
                  <input class="form-input" :class="{ 'error': errorMessages.username, 'opacity-50': !authUserIsAdmin }"
                    type="text" v-model="username" :disabled="!authUserIsAdmin">
                  <p v-show="errorMessages.username" class="message-error">{{ errorMessages.username }}</p>
                </div>
                <div class="form-item">
                  <label class="form-label">Полное имя*</label>
                  <input class="form-input"
                    :class="{ 'error': errorMessages.full_name, 'opacity-50': !authUserIsAdmin }" type="text"
                    v-model="full_name" :disabled="!authUserIsAdmin">
                  <p v-show="errorMessages.full_name" class="message-error">{{ errorMessages.full_name }}</p>
                </div>
                <div class="form-item-wrapper">
                  <div class="form-item">
                    <label class="form-label">Телефон*</label>
                    <input class="form-input"
                      :class="{ 'error': errorMessages.phone_number, 'opacity-50': !authUserIsAdmin }" type="tel"
                      v-model="phone_number" :disabled="!authUserIsAdmin">
                    <p v-show="errorMessages.phone_number" class="message-error">{{ errorMessages.phone_number }}</p>
                  </div>
                  <div class="form-item">
                    <label class="form-label">E-mail*</label>
                    <input class="form-input" :class="{ 'error': errorMessages.email, 'opacity-50': !authUserIsAdmin }"
                      type="email" v-model="email" :disabled="!authUserIsAdmin">
                    <p v-show="errorMessages.email" class="message-error">{{ errorMessages.email }}</p>
                  </div>
                </div>
                <BusinessRegionsSelector ref="regions" v-model="business_regions" :show-selected="true"
                  :disabled="isAdminOrCallCenterEmployeeSelected">
                  <template v-slot:error>
                    <p v-show="errorMessages.business_regions" class="message-error">{{ errorMessages.business_regions
                    }}</p>
                  </template>
                </BusinessRegionsSelector>
              </div>
              <div class="content-item">
                <div class="form-item">
                  <p class="form-label">Роль пользователя</p>
                  <template>
                    <el-select v-model="role_id" placeholder="Роль пользователя" class="form-select"
                      :class="{ 'error': role_id === null }">
                      <el-option class="form-option" v-for="role in roles" :key="role.id" :label="role.name"
                        :value="role.id">
                      </el-option>
                    </el-select>
                  </template>
                </div>
                <div class="form-item">
                  <input id="active" class="form-input-check" type="checkbox" v-model="is_active">
                  <label for="active" class="form-label form-label-check">Активный</label>
                </div>
                <div class="form-item">
                  <p class="form-label form-label--bold">Изменить пароль</p>
                </div>
                <div class="form-item">
                  <input id="create-pass" class="form-input-radio" name="pass" type="radio" :value="true"
                    v-model="generatePassword">
                  <label for="create-pass" class="form-label form-label-radio">Сгенерировать пароль</label>
                </div>
                <div class="form-item">
                  <input id="my-pass" class="form-input-radio" name="pass" type="radio" :value="false"
                    v-model="generatePassword">
                  <label for="my-pass" class="form-label form-label-radio">Я укажу пароль сам</label>
                </div>
                <div class="form-item" :class="{ 'opacity-50': generatePassword === true }">
                  <label class="form-label">Новый пароль</label>
                  <input :disabled="generatePassword === true" class="form-input"
                    :class="{ 'cursor-default': generatePassword === true, 'error': errorMessages.password }"
                    type="password" v-model="password">
                  <p v-show="errorMessages.password" class="message-error">{{ errorMessages.password }}</p>
                </div>
                <div class="form-item" :class="{ 'opacity-50': generatePassword === true }">
                  <label class="form-label">Подтвердить пароль</label>
                  <input :disabled="generatePassword === true" class="form-input"
                    :class="{ 'cursor-default': generatePassword === true, 'error': errorMessages.password_2 }"
                    type="password" v-model="password_2">
                  <p v-show="errorMessages.password_2" class="message-error">{{ errorMessages.password_2 }}</p>
                </div>
                <div class="form-item">
                  <p class="text-small">Новый пароль будет выслан сотруднику на указаный e-mail.</p>
                </div>
              </div>
            </div>
          </form>
        </template>
        <template v-if="tab === 1">
          <div class="tab-content">
            <p></p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '../../constants/Constants';
import ValidationHelper from '../../helpers/ValidationHelper';
import BusinessRegionsSelector from '../../components/BusinessRegionsSelector.vue';

const _ = require('lodash');

export default {
  name: 'UserEdit',
  components: {
    BusinessRegionsSelector
  },
  props: {},
  data() {
    return {
      user: {},
      email: '',
      username: '',
      full_name: '',
      phone_number: '',
      role_id: null,
      is_active: true,
      password: null,
      password_2: null,
      business_regions: [],

      tab: 0,
      businessRegionsSort: 'asc',
      generatePassword: false,
      watchRegions: false,
      isAdminOrCallCenterEmployeeSelected: false,

      errorMessages: {
        username: null,
        email: null,
        full_name: null,
        phone_number: null,
        role_id: null,
        business_regions: null,
        password: null,
        password_2: null
      }
    }
  },
  async created() {
    await this.fetchUser(this.$route.params.userId);
    this.watchRegions = true;
  },
  watch: {
    email() {
      this.emailValidator();
    },
    username() {
      this.usernameValidator();
    },
    full_name() {
      this.fullNameValidator();
    },
    phone_number() {
      this.phoneNumberValidator();
    },
    password() {
      this.passwordValidator();
      this.password2Validator();
    },
    password_2() {
      this.password2Validator();
    },
    generatePassword() {
      this.password = '';
      this.password_2 = '';
    },
    business_regions() {
      this.businessRegionsValidator();
    },
    async role_id(id) {
      if (this.watchRegions) {
        await this.clearBusinessRegions();
        this.isAdminOrCallCenterEmployeeSelected = this.isAdminOrCallCenterEmployee(id);
        if (this.isAdminOrCallCenterEmployeeSelected) {
          await this.selectAllBusinessRegions();
        }
      }
    }
  },
  methods: {
    async selectAllBusinessRegions() {
      const regionsSelector = this.$refs.regions;
      if (regionsSelector) {
        await regionsSelector.selectAllBusinessRegions();
      }
    },
    async clearBusinessRegions() {
      const regionsSelector = this.$refs.regions;
      if (regionsSelector) {
        await regionsSelector.clearBusinessRegions();
      }
    },
    async fetchUser(id) {
      const user = await this.$services.UserService.getUser(id);
      this.user = user;
      this.id = user.id;
      this.email = user.email;
      this.username = user.username;
      this.full_name = user.full_name;
      this.phone_number = user.phone_number;
      this.role_id = user.role_id;
      this.is_active = user.is_active;

      this.isAdminOrCallCenterEmployeeSelected = this.isAdminOrCallCenterEmployee(user.role_id);
      if (this.isAdminOrCallCenterEmployeeSelected) {
        await this.selectAllBusinessRegions();
      } else {
        this.business_regions = user.business_regions;
      }
    },
    async updateUser() {
      if (this.dataIsValid()) {
        let data = {}
        for (const attr of Object.getOwnPropertyNames(this.user)) {
          if (this[attr] !== undefined) {
            let add = false;
            if (Array.isArray(this[attr]) && Array.isArray(this.user[attr])) {
              add = _.difference(this[attr], this.user[attr]).length
                + _.difference(this.user[attr], this[attr]).length > 0;
            } else {
              add = !_.isEqual(this[attr], this.user[attr]);
            }
            if (add) {
              data[attr] = this[attr];
            }
          }
        }
        if (!this.generatePassword && this.email && this.password_2) {
          data = Object.assign(data, { password: this.password, password_2: this.password_2 });
        } else if (this.generatePassword) {
          data = Object.assign(data, { password_change_type: 'generate_password' });
        }
        if (data.password && data.password_2 && !data.password_change_type) {
          data = Object.assign(data, { password_change_type: 'manual_input' });
        }
        try {
          await this.$services.UserService.updateUser(this.user.id, data);
          const updated = JSON.parse(JSON.stringify(data));
          let message = '';
          if (Object.getOwnPropertyNames(updated).length === 1 && updated.is_active !== undefined) {
            message = updated.is_active === true ? 'Пользователь активирован' : 'Пользователь деактивирован';
          } else {
            message = 'Данные пользователя изменены';
            message = data.password_change_type ? `${message}, на e-mail пользователя отправлено письмо с новым паролем` : message;
          }
          this.$services.MessageService.success(message);
          await this.closeUser();
        } catch (e) {
          if (e.data && e.data.detail) {
            switch (e.data.detail) {
              case 'user_with_username_already_exists': this.errorMessages.username = 'Пользователь с таким именем уже существует'; break;
              case 'user_with_email_already_exists': this.errorMessages.email = 'Пользователь с таким e-mail уже существует'; break;
              default: break;
            }
          }
        }
      }
    },
    closeUser() {
      this.$router.push({ name: 'users-list' })
    },
    emailIsValid(email) {
      return ValidationHelper.emailIsValid(email);
    },
    passwordIsValid(password) {
      return ValidationHelper.passwordIsValid(password);
    },
    dataIsValid() {
      const errors = JSON.parse(JSON.stringify(this.errorMessages));
      return Object.getOwnPropertyNames(errors).every(attr => errors[attr] === null);
    },
    isAdminOrCallCenterEmployee(id) {
      const roles = this.roles.filter(role => [Constants.USER_ROLE_CODE.ADMIN, Constants.USER_ROLE_CODE.CALL_CENTER_EMPLOYEE].includes(role.code)).map(role => role.id);
      return roles.includes(id);
    },
    emailValidator() {
      let message = null;
      if (!this.email) {
        message = 'Это поле обязательно к заполнению';
      } else if (!ValidationHelper.emailIsValid(this.email)) {
        message = 'Некорректный адрес электронной почты';
      }
      this.errorMessages.email = message;
    },
    usernameValidator() {
      let message = null;
      if (!this.username) {
        message = 'Это поле обязательно к заполнению';
      }
      this.errorMessages.username = message;
    },
    fullNameValidator() {
      let message = null;
      if (!this.full_name) {
        message = 'Это поле обязательно к заполнению';
      }
      this.errorMessages.full_name = message;
    },
    phoneNumberValidator() {
      let message = null;
      if (!this.phone_number) {
        message = 'Это поле обязательно к заполнению';
      } else if (!ValidationHelper.phoneNumberIsValid(this.phone_number)) {
        message = 'Неверный формат телефонного номера';
      }
      this.errorMessages.phone_number = message;
    },
    businessRegionsValidator() {
      let message = null;
      if (this.business_regions.length === 0) {
        message = 'Выберите хотя бы один бизнес-регион';
      }
      this.errorMessages.business_regions = message;
    },
    passwordValidator() {
      let message = null;
      const check = ValidationHelper.validatePassword(this.password);
      if (!this.generatePassword) {
        if (!this.password) {
          message = 'Это поле обязательно к заполнению';
        } else if (!check.isValid) {
          message = check.errorMessage;
        }
      }
      this.errorMessages.password = message;
    },
    password2Validator() {
      let message = null;
      const check = ValidationHelper.validatePassword(this.password);
      if (!this.generatePassword) {
        if (!this.password_2) {
          message = 'Это поле обязательно к заполнению';
        } else if (!check.isValid) {
          message = check.errorMessage;
        } else if (this.password !== this.password_2) {
          message = 'Пароли не совпадают';
        }
      }
      this.errorMessages.password_2 = message;
    }
  },
  computed: {
    roles() {
      return this.$store.getters['user/getRoles'];
    },
    authUserIsAdmin() {
      const authUser = this.authUser;
      return authUser && this.authUser.role_id === this.adminRoleId;
    },
    adminRoleId() {
      return this.$store.getters['user/getAdminRoleId'];
    },
    authUser() {
      return this.$store.getters['auth/getAuthUser'];
    }
  }
}
</script>

<style scoped>
.user-page-wrapper {
  padding-bottom: 100px;
}

.user-create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.title {
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
}

.btn-wrapper {
  display: flex;
  padding: 15px 7.5px;
  justify-content: space-between;
  align-items: center;
  background: #E5E5E5;
  border: 1px solid #BFBFBF;
  border-top: 0;
}

.btn-top {
  margin: 0 7.5px;
}

.create {
  border: 1px solid #BFBFBF;
}

.form {
  padding: 20px 25px 80px 25px;
  background: #F2F2F2;
}

.tabs {
  display: flex;
}

.tab {
  font-size: 18px;
  line-height: 24px;
  padding: 13px 24px;
  cursor: pointer;
}

.tab.active {
  background: #000000;
  color: #FFFFFF;
}

.tab-content {
  display: flex;
  justify-content: space-between;
}

.content-item {
  width: 50%;
  margin-right: 50px;
}

.text {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}

.form-item {
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.message-error {
  color: #DC143C;
  font-size: 14px;
  line-height: 17px;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper {
  display: flex;
  justify-content: space-between;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.form-label-check {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-check::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  background: #fff;
  border: 1px solid #000000;
}

.form-label-check::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: url("../../assets/images/svg/checked.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.form-input-check[type="checkbox"]:checked+.form-label-check::after {
  opacity: 1;
}

.form-input-check[type="checkbox"]:checked+.form-label-check::before {
  background: #000;
}

.form-input-check {
  display: none;
}

.form-label-radio {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-radio::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #4E4E4E;
}

.form-label-radio::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #000000;
  border: 1px solid #4E4E4E;
  z-index: 2;
  opacity: 0;
}

.form-input-radio {
  display: none;
}

.form-input-radio[type="radio"]:checked+.form-label-radio::after {
  opacity: 1;
}

.form-label--bold {
  font-weight: 600;
}

.text-small {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}

/*TODO: select*/
.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.el-select .el-input__suffix {
  background: #000000;
}

.el-select .el-input .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-icon-arrow-up:before {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-input__prefix,
.el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__inner {
  padding-left: 10px;
  padding-right: 0;
  border-radius: 0;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #000000;
}

.el-select .el-input__inner:focus {
  border-color: #000000;
}

.el-select-dropdown__list {
  padding: 0;
}

.el-select-dropdown__item {
  padding: 10px;
  height: auto;
  color: #000000;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #58BCAF;
  color: #FFFFFF;
}

.el-select-dropdown__item:hover+.el-select-dropdown__item {
  background: #FFFFFF;
}

.region-title {
  display: flex;
  align-items: baseline;
}

.region-list {
  border-top: 2px solid #000000;
}

.region-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px;
  font-size: 18px;
  line-height: 24px;
  transition: all .3s ease-in-out;
  background: #FFFFFF;
}

.region-list__item:hover {
  background: #E5E5E5;
}

.region-list__close-icon {
  width: 14px;
  height: 14px;
}

.region-list__icon {
  width: 9px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.region-list__icon-rotate {
  transform: rotate(180deg);
}
</style>
