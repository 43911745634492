import axios from '@/axios';

export default {
    async fetchSocials({ commit }) {
      const params = { page_size: 10000, ordering: 'name' };
      const response = await axios.get('/api/v1/social_networks', { params });
      commit('SET_SOCIALS', response.data.results);
    },
    setSocials({ commit }, socials) {
      commit('SET_SOCIALS', socials);
    }
}
